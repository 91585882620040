/**
 * Service that allows for placing orders
 */
export class CheckoutService {
    constructor(params) {
        this.rpcService = params.rpcService;
    }
    /**
     * Place an order
     */
    async checkout(request) {
        return this.rpcService.rpc("PlaceOrderRequest", request);
    }
}
