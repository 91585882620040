import { makeAutoObservable } from "mobx";
import { makeImmutable } from "../core";
import { MoneySpectrum } from "../pricing/Price";
import { PPrice } from "./PPrice";
import { LabelsCreator } from "../labels";
export class PMenuSectionItemSingleProductContent {
    constructor(params) {
        this.type = "SingleProduct";
        this.id = params.id;
        this._name = params.name;
        this.description = params.description;
        this.variants = params.variants;
        this.columns = params.columns;
        this.availabilitySchedule = params.availabilitySchedule;
        this.localizationProvider = params.localizationProvider;
        this.photoUrn = params.photoUrn;
        this.tags = params.tags;
        this.allergens = params.allergens;
        this.freeModifierItemsCount = params.freeModifierItemsCount;
        this.baseIngredients = params.baseIngredients;
        this.purchaseOrderScope = params.purchaseOrderScope;
        makeAutoObservable(this);
    }
    get name() {
        return this._name.value;
    }
    get aggregatedPrice() {
        const aggregated = this.variants
            .map((variant) => variant.price.spectrum)
            .reduce((prev, curr) => MoneySpectrum.union(prev, curr), {
            type: "null",
        });
        return new PPrice(this.localizationProvider, aggregated);
    }
    get instances() {
        return this.variants.map((variant) => variant.instance);
    }
    get labelsCreator() {
        return new LabelsCreator({
            scope: this.purchaseOrderScope,
            productInstances: this.instances,
            view: "Menu",
        });
    }
    get dropdown() {
        switch (this.variants.length) {
            case 0:
                return null;
            case 1:
                return { type: "SingleDropdown", item: this.variants[0] };
            default:
                return {
                    type: "VariantsDropdown",
                    price: this.aggregatedPrice,
                    isAvailable: this.variants.some((variant) => variant.isAvailable),
                    items: this.variants,
                };
        }
    }
    get productAllergens() {
        return this.allergens.rawValues();
    }
}
export class PMenuSectionItemCreatorContent {
    constructor(params) {
        this.type = "Creator";
        this.variant = params.variant;
        this._name = params.name;
        this._description = params.description;
        this.photoUrn = params.photoUrn;
        makeAutoObservable(this);
    }
    get name() {
        return this._name.value;
    }
    get description() {
        return this._description.value;
    }
    get instances() {
        return [this.variant.instance];
    }
}
export class PMenuSectionItem {
    constructor(params) {
        this.id = params.id;
        this.content = params.content;
        makeImmutable(this);
    }
}
