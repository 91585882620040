import { Fragment, h } from "preact";
import { observer } from "mobx-react";
import CreatorPartsField from "./CreatorPartsField";
import CreatorSplitRow from "./CreatorSplitRow";
import CreatorConfig from "./CreatorConfig";
import AvailabilityDebugInfo from "../../components/AvailabilityDebugInfo";
import CreatorQuantityStepper from "./CreatorQuantityStepper";
import Alert from "../../components/Alert";
import CrossSellingList from "../../components/CrossSellingList";
function CreatorForm(props) {
    const { creator } = props;
    const { isSplitPossible } = creator;
    const crossSellingItems = creator.crossSellingCreator.items.raw;
    return (h("form", { class: "m-form" },
        creator.orderingHoursMessage !== null && (h(Alert, { type: creator.orderingHoursMessage.level, message: creator.orderingHoursMessage.message, fieldId: "creator.error" })),
        h(AvailabilityDebugInfo, { fieldId: "Creator.availability", name: "ProductInstance", availability: creator.availability }),
        h(CreatorConfig, { fieldIdPrefix: "Creator.", config: creator.state.commonConfigCreator }),
        creator.state.type === "SinglePartCreator" && (h(Fragment, null,
            h("span", { "data-field-type": "info", "data-field-id": "Creator.parts.0.productId", "data-value": creator.state.part.productTypeId }),
            h(AvailabilityDebugInfo, { fieldId: "Creator.parts.0.availability", name: "ProductPart", availability: creator.state.part.availability }),
            h(CreatorConfig, { fieldIdPrefix: "Creator.parts.0.", config: creator.state.part.partConfigCreator }),
            isSplitPossible && (h(CreatorSplitRow, { onClick: () => creator.split() })),
            creator.state.part.error !== null && (h(Alert, { type: creator.state.part.error.level, message: creator.state.part.error.message })))),
        creator.state.type === "HalvesCreator" && (h(Fragment, null,
            h(AvailabilityDebugInfo, { fieldId: "Creator.parts.0.availability", name: "ProductPart", availability: creator.state.firstHalf.availability }),
            h(CreatorPartsField, { index: 0, fieldIdPrefix: "Creator.parts.0", value: creator.state.firstHalf.productTypeId, onChange: (productType) => creator.setFirstHalfProductType(productType), options: creator.state.firstHalf.possibleProductTypes, error: creator.state.firstHalf.error, onDeleteItem: () => creator.removeFirstHalf() }),
            h(CreatorConfig, { fieldIdPrefix: "Creator.parts.0.", config: creator.state.firstHalf.partConfigCreator }),
            h(AvailabilityDebugInfo, { fieldId: "Creator.parts.1.availability", name: "ProductPart", availability: creator.state.secondHalf.availability }),
            h(CreatorPartsField, { index: 1, fieldIdPrefix: "Creator.parts.1", value: creator.state.secondHalf.productTypeId, onChange: (productType) => creator.setSecondHalfProductType(productType), options: creator.state.secondHalf.possibleProductTypes, error: creator.state.secondHalf.error, onDeleteItem: () => creator.removeSecondHalf() }),
            h(CreatorConfig, { fieldIdPrefix: "Creator.parts.1.", config: creator.state.secondHalf.partConfigCreator }))),
        creator.error !== null && (h(Alert, { type: creator.error.level, message: creator.error.message, fieldId: "creator.error" })),
        h(CreatorQuantityStepper, { creator: creator }),
        crossSellingItems.length > 0 && (h(CrossSellingList, { fieldId: "creator.crossSelling", items: crossSellingItems }))));
}
export default observer(CreatorForm);
