import { makeAutoObservable } from "mobx";
import { associationKey, } from "../core";
import { AddingDuplicateElementError, UnknownElementError } from "./errors";
import { RArray } from "./RArray";
import { RMap } from "./RMap";
import { RSet } from "./RSet";
import { Sentry } from "../Sentry";
/*
 * Restaumatic Map wrapper including helper map operations. Uses `AssociationKey` as an association
 */
export class RMutableMap {
    constructor(params) {
        this.rawKeys = params.rawKeys;
        this.rawValues = params.rawValues;
        makeAutoObservable(this);
    }
    static empty() {
        return new RMutableMap({
            rawKeys: new Map(),
            rawValues: new Map(),
        });
    }
    static fromIterable(iterable, options) {
        var _a;
        const array = [...iterable];
        const rawKeys = new Map(array.map(([key]) => [associationKey(key), key]));
        const ignoreDuplicates = (_a = options === null || options === void 0 ? void 0 : options.ignoreDuplicates) !== null && _a !== void 0 ? _a : false;
        if (!ignoreDuplicates && rawKeys.size !== array.length) {
            Sentry.logError(AddingDuplicateElementError.fromArray(array.map(([k, _]) => k)));
        }
        const rawValues = new Map(array.map(([key, value]) => [associationKey(key), value]));
        return new RMutableMap({ rawKeys, rawValues });
    }
    [Symbol.iterator]() {
        return this.entries[Symbol.iterator]();
    }
    get size() {
        return this.rawKeys.size;
    }
    get isEmpty() {
        return this.rawKeys.size === 0;
    }
    includes(key) {
        return this.rawKeys.has(associationKey(key));
    }
    rawFind(key) {
        var _a;
        return (_a = this.rawValues.get(key)) !== null && _a !== void 0 ? _a : null;
    }
    find(key) {
        return this.rawFind(associationKey(key));
    }
    rawGet(key) {
        const element = this.rawFind(key);
        if (element === null) {
            throw new UnknownElementError();
        }
        return element;
    }
    get(key) {
        return this.rawGet(associationKey(key));
    }
    get keys() {
        return RSet.fromIterable(this.rawKeys.values());
    }
    get values() {
        return new RArray([...this.rawValues.values()]);
    }
    get entries() {
        return new RArray([...this.rawKeys.entries()].map(([associationKey, key]) => [key, this.rawValues.get(associationKey)]));
    }
    get sample() {
        for (const value of this.rawValues.values()) {
            return value;
        }
        return null;
    }
    /*
     * Mutation operatoions
     */
    update(key, value) {
        if (!this.includes(key)) {
            throw new Error("Unable to update not existing element");
        }
        const ak = associationKey(key);
        this.rawValues.set(ak, value);
    }
    set(key, value) {
        if (this.includes(key)) {
            Sentry.logError(AddingDuplicateElementError.fromSingleton(key));
        }
        const ak = associationKey(key);
        this.rawKeys.set(ak, key);
        this.rawValues.set(ak, value);
    }
    getOrCreate(key, factory) {
        if (!this.includes(key)) {
            this.set(key, factory());
        }
        return this.get(key);
    }
    delete(key) {
        if (!this.includes(key)) {
            throw new UnknownElementError();
        }
        const ak = associationKey(key);
        this.rawKeys.delete(ak);
        this.rawValues.delete(ak);
    }
    clear() {
        this.rawKeys.clear();
        this.rawValues.clear();
    }
    freeze() {
        return RMap.fromIterable(this);
    }
}
