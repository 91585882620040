/* See `doc/FrontendRouting.md` for a description of what happens here. */
import Modal from "bootstrap/js/dist/modal";
import { CREATOR_HASH_PREFIX, CREATOR_MODAL_ID, } from "../views/ProductCard/init";
const MODAL_HASH_PREFIX = "modal";
// Define this object for external usage of Bootstrap 5 Modal instance
window.Modal = Modal;
/**
 * Check if location hash is #modal-*.
 *
 * @returns {boolean}
 */
function isHashModal() {
    const modalHashRegex = new RegExp(MODAL_HASH_PREFIX);
    return modalHashRegex.test(window.location.hash.substring(1, MODAL_HASH_PREFIX.length + 1));
}
/** Check if location hash is for product creator */
function isCreatorHash() {
    return location.hash.startsWith(CREATOR_HASH_PREFIX);
}
/**
 * Check if modal is close.
 *
 * @returns {boolean}
 */
function isModalClose() {
    const openModals = document.querySelectorAll(".modal.show").length;
    return openModals === 0;
}
/**
 * Get modal id from location hash
 *
 * @returns {string}
 */
function getModalIdFromHash() {
    if (isCreatorHash()) {
        return CREATOR_MODAL_ID;
    }
    return window.location.hash.replace(`#${MODAL_HASH_PREFIX}-`, "");
}
let numOperationsInProgress = 0;
let hashChangePending = false;
// e2e synchronization functions
const addTask = "__addTask__" in window
    ? window.__addTask__
    : () => { };
const doneTask = "__doneTask__" in window
    ? window.__doneTask__
    : () => { };
/** Register modal operation start. See updateModalFromHash. */
function onOperationStarted(name) {
    numOperationsInProgress++;
    console.debug(`Modals: operation ${name} started, numOperationsInProgress=${numOperationsInProgress}`);
    addTask(`Modals:${name}`);
}
/** Register modal operation end. See updateModalFromHash. */
function onOperationFinished(name) {
    numOperationsInProgress--;
    console.debug(`Modals: operation ${name} finished, numOperationsInProgress=${numOperationsInProgress}`);
    doneTask(`Modals:${name}`);
    if (numOperationsInProgress < 0) {
        console.error("Modals: invariant violated, numOperationsInProgress=" +
            numOperationsInProgress);
    }
    if (numOperationsInProgress === 0 && hashChangePending) {
        console.debug("Modals: executing deferred updateModalFromHash");
        hashChangePending = false;
        updateModalFromHash();
    }
}
let creatorHandler = null;
/**
 * Set the creator handler function.
 */
export function setCreatorHandler(handler) {
    creatorHandler = handler;
    console.debug("Modals: creator handler installed");
}
/**
 * Handle hash change, possibly showing or hiding modals.
 *
 * See handleModalsOnBrowserNav for details.
 */
export function updateModalFromHash() {
    if (numOperationsInProgress > 0) {
        console.debug("Modals: operation in progress, deferring updateModalFromHash");
        hashChangePending = true;
        return;
    }
    // Hide other modals if we're showing a new one
    const modalId = getModalIdFromHash();
    const otherShownModals = [
        ...document.querySelectorAll(".modal.show"),
    ].filter((elem) => elem.id !== modalId);
    if (otherShownModals.length > 0) {
        const currentShownModal = otherShownModals[0];
        console.debug(`Modals: hiding previous modal ${currentShownModal.id}`);
        Modal.getOrCreateInstance(currentShownModal).hide();
        // We need to wait for it to hide; enqueue the next operation
        updateModalFromHash();
        return;
    }
    if (isCreatorHash()) {
        const params = location.hash.substring(CREATOR_HASH_PREFIX.length);
        if (creatorHandler) {
            console.debug("Modals: opening creator from hash");
            creatorHandler(params);
        }
        else {
            console.debug("Modals: no creator handler installed");
        }
    }
    else if (isHashModal()) {
        const elem = document.getElementById(modalId);
        if (!elem) {
            console.debug(`Modals: no element match modal identifier ${modalId}, ignoring hash change`);
            return;
        }
        const modal = Modal.getOrCreateInstance(elem);
        // @ts-expect-error
        const isModalShown = modal._isShown;
        if (isModalShown) {
            console.debug(`Modals: ${modalId} already shown, ignoring hash change`);
            return;
        }
        if (elem.classList.contains("js-modal--uninitialized")) {
            console.debug(`Modals: ${modalId} is uninitialized, not showing and clearing its history entry`);
            history.replaceState(null, "", location.pathname);
            return;
        }
        console.debug(`Modals: showing ${modalId} from hash`);
        modal.show();
    }
    else {
        const elem = document.querySelector(".modal.show");
        if (!elem) {
            console.debug(`Modals: no modal shown, ignoring hash change`);
            return;
        }
        const modal = Modal.getOrCreateInstance(elem);
        modal.hide();
        console.debug(`Modals: hiding ${elem.getAttribute("id")} from hash`);
    }
}
/**
 * Register event listeners to handle browser back button action on Modals.
 *
 * One difficulty is that Bootstrap doesn't seem to handle well issuing another modal operation (show or hide) when one is in progress.
 * This could happen when rapidly switching between history entries.
 * We prevent that in the following way:
 *
 * - We track whether a modal operation is in progress by listening to `{show,shown,hide,hidden}.bs.modal` events and maintaining a counter (numOperationsInProgress).
 *
 * - In `updateModalFromHash`, if there is an operation in progress, we defer the handling by setting a flag (`hashChangePending`).
 *
 * - In `onOperationFinished`, when all operations are done, we execute any pending hash changes.
 *
 * Another thing we need to guard against is redundant operations. For example, when three transitions are issued rapidly in sequence:
 * - `#modal-cart`
 * - no hash
 * - `#modal-cart` again
 *
 * then the first transition will issue `show`, the second will be deferred, and the third will be deferred.
 * The deferred updateModalFromHash would attempt to execute `show` again.
 *
 * But Bootstrap doesn't handle redundant operations well - it would trigger a `show` event, but no `shown`, which messes up our counting.
 * We guard against that by checking the modal state and ignoring a show request if modal is already shown (or showing).
 */
function handleModalsOnBrowserNav() {
    window.addEventListener("hashchange", updateModalFromHash);
    document.addEventListener("show.bs.modal", function (e) {
        const modalId = e.target.id;
        onOperationStarted(`show ${modalId}`);
        if (!(isHashModal() || isCreatorHash())) {
            const path = `${window.location.pathname}#${MODAL_HASH_PREFIX}-${modalId}`;
            if (nextModalShouldReplaceHash) {
                console.debug(`Modals: showing modal ${modalId} with replaceState`);
                nextModalShouldReplaceHash = false;
                history.replaceState(null, "", path);
            }
            else {
                history.pushState(null, "", path);
            }
        }
    });
    document.addEventListener("hide.bs.modal", function (e) {
        const modalId = e.target.id;
        onOperationStarted(`hide ${modalId}`);
        // Clear hash only if it represents the current modal.
        // (If it's another one, we may be in a process of switching history entries)
        if (getModalIdFromHash() === modalId) {
            if (nextModalShouldReplaceHash) {
                console.debug("Modals: hiding modal with replaceState");
                history.replaceState(null, "", window.location.pathname);
                // Note: not clearing nextModalShouldReplaceHash, it should also affect the subsequent `show` event
            }
            else {
                history.pushState(null, "", window.location.pathname);
            }
        }
    });
    document.addEventListener("shown.bs.modal", (e) => {
        const modalId = e.target.id;
        onOperationFinished(`show ${modalId}`);
    });
    document.addEventListener("hidden.bs.modal", (e) => {
        const modalId = e.target.id;
        onOperationFinished(`hide ${modalId}`);
    });
}
let nextModalShouldReplaceHash = false;
export function setNextModalShouldReplaceHash() {
    nextModalShouldReplaceHash = true;
}
/**
 * Make sure required `modal-open` body class is present when opening one modal from within the other.
 *
 * Fixes sequential modals when events order can be as follows: hide, show, hidden, shown and `modal-open` class is missing.
 */
function initSequentialModals() {
    document.addEventListener("shown.bs.modal", function () {
        var _a;
        if (!isModalClose()) {
            (_a = document.querySelector("body")) === null || _a === void 0 ? void 0 : _a.classList.add("modal-open");
        }
    });
}
/**
 * Show a Bootstrap 5 modal.
 * Returns Promise which resolves when the modal is shown.
 */
export function showModal(modalId) {
    const element = document.getElementById(modalId);
    if (!element || !isValidModalElement(element)) {
        console.log("Modal element not found, ignoring showModal()");
        return Promise.resolve(false);
    }
    const modal = Modal.getOrCreateInstance(element);
    // @ts-expect-error
    if (modal && modal._isShown) {
        console.log("Modal already shown, ignoring showModal()");
        return Promise.resolve(false);
    }
    const finished = new Promise((resolve) => element.addEventListener("shown.bs.modal", () => resolve(true)));
    modal.show();
    return finished;
}
/**
 * Hide a Bootstrap 5 modal.
 * Returns Promise which resolves when the modal is hidden.
 */
export function hideModal(modalId) {
    const element = document.getElementById(modalId);
    if (!element || !isValidModalElement(element)) {
        console.log("Modal element not found, ignoring hideModal()");
        return Promise.resolve(false);
    }
    const modal = Modal.getOrCreateInstance(element);
    // @ts-expect-error
    if (!modal || !modal._isShown) {
        console.log("Modal already hidden, ignoring hideModal()");
        return Promise.resolve(false);
    }
    const finished = new Promise((resolve) => element.addEventListener("hidden.bs.modal", () => resolve(true)));
    modal.hide();
    return finished;
}
/**
 * Hide current modal and after hidden, show next.
 */
export function switchModals(currentModalId, nextModalId) {
    return hideModal(currentModalId).then(() => showModal(nextModalId));
}
function isValidModalElement(element) {
    const instance = Modal.getOrCreateInstance(element);
    // @ts-expect-error
    return instance !== null && instance._dialog !== null;
}
/**
 * Modals.
 *
 * https://getbootstrap.com/docs/3.3/javascript/#modals
 */
export default {
    init: function () {
        handleModalsOnBrowserNav();
        initSequentialModals();
        updateModalFromHash();
    },
};
