import { action, makeAutoObservable, reaction, runInAction } from "mobx";
import { observer } from "mobx-react";
import { h, render } from "preact";
import { Tracking, debugAvailabilityErrors, makeImmutable, } from "restaumatic-client";
import * as Modals from "../../utils/Modals";
import Creator from "./Creator";
import { scrollToError } from "../../utils/Scroll";
export const CREATOR_MODAL_ID = "product-creator";
export const CREATOR_HASH_PREFIX = "#product/";
export function init(options) {
    console.log("Initializing new ProductCard");
    const state = new ModalState();
    const modalElement = state.createModalElement(CREATOR_MODAL_ID);
    render(h(CreatorModal, { state: state }), modalElement);
    return (productCardCreator) => {
        void Modals.showModal(CREATOR_MODAL_ID);
        runInAction(() => {
            state.productCard = new ProductCard({
                creator: productCardCreator,
                addToCart: (creatorResult) => {
                    Modals.setNextModalShouldReplaceHash();
                    // TODO: switch to async/await once transpilation is sorted out
                    void Modals.hideModal(CREATOR_MODAL_ID).then(action(() => {
                        options.addToCart(creatorResult);
                    }));
                },
            });
        });
    };
}
class ModalState {
    constructor() {
        this.isOpen = false;
        this.productCard = null;
        makeAutoObservable(this);
        reaction(() => this.isOpen && this.hash, () => {
            if (this.isOpen && this.hash !== null && location.hash !== this.hash) {
                history.replaceState(null, "", window.location.pathname + this.hash);
            }
        });
    }
    get hash() {
        return this.productCard !== null
            ? `${CREATOR_HASH_PREFIX}${this.productCard.creator.productTypeIdValues.join("/")}`
            : null;
    }
    createModalElement(id) {
        const modalElement = document.createElement("div");
        modalElement.id = id;
        modalElement.classList.add("m-product-card", "modal", "fade");
        modalElement.tabIndex = -1;
        modalElement.addEventListener("show.bs.modal", action(() => {
            this.isOpen = true;
        }));
        modalElement.addEventListener("hide.bs.modal", action(() => {
            this.isOpen = false;
        }));
        document.body.appendChild(modalElement);
        return modalElement;
    }
}
export class ProductCard {
    constructor(params) {
        this.creator = params.creator;
        this._addToCart = params.addToCart;
        makeImmutable(this);
    }
    addToCart() {
        const result = this.creator.finish();
        if (result !== null) {
            this._addToCart(result);
        }
        else {
            scrollToError(`#${CREATOR_MODAL_ID}`, [
                ".alert-danger",
                ".alert-warning",
                ".has-error",
                ".m-availability__label--unavailable",
            ]);
            Tracking.trackEvent({
                tag: "ProductCardAvailabilityError",
                contents: {
                    restaurantId: this.creator.restaurantId.value,
                    name: this.creator.product.name,
                    productIds: this.creator.productTypeIdValues,
                    totalPrice: this.creator.priceValue,
                    errors: debugAvailabilityErrors(this.creator.availability),
                },
            });
        }
    }
}
const CreatorModal = observer(({ state }) => {
    return (h("div", { className: "modal-dialog" },
        h("div", { className: "modal-content" }, state.productCard ? h(Creator, { productCard: state.productCard }) : null)));
});
