import { makeAutoObservable } from "mobx";
import { Availability } from "../..";
import { RArray, RSet } from "../../collections";
import { ProductCardCreatorResult } from "./ProductCardCreatorResult";
export class ProductCardCreator {
    constructor(params) {
        this.productInstanceCreator = params.productInstanceCreator;
        this.isIosReviewMode = params.isIosReviewMode;
        this._quantity = params.quantity;
        this.crossSellingCreator = params.crossSellingCreator;
        this.orderingHoursAvailability = params.orderingHoursAvailability;
        makeAutoObservable(this);
    }
    initWithScope(parentScope) {
        this.productInstanceCreator.initWithScope(parentScope);
        this.crossSellingCreator.initWithScope(parentScope);
        this.updateCrossSellingProductTypes();
    }
    get availability() {
        // NOTICE Cross selling creator contains only available elements so it cannot influence availability negatively
        return Availability.composite([
            this.productInstanceCreator.instance.availability,
            this.orderingHoursAvailability,
        ]);
    }
    get productTypeIds() {
        return this.productInstanceCreator.productTypeIds;
    }
    get quantity() {
        return this._quantity;
    }
    increment() {
        this._quantity = this.quantity.incremented();
    }
    decrement() {
        this._quantity = this.quantity.decremented();
    }
    get hasResult() {
        // NOTICE: This is a hack to allow to add a time-based unavailable Product to the Cart
        // TODO: add creator.fulfillmentTime.isEditable logic (has product any available fulfillment time to order to)
        if ((this.availability.isAvailable.isDefinitelyFalse &&
            this.availability.unavailabilityReasons.eq(RSet.singleton("ProductTypeTimeBased"))) ||
            this.isIosReviewMode) {
            return true;
        }
        if (this.availability.isAvailable.isDefinitelyFalse) {
            return false;
        }
        return true;
    }
    get result() {
        if (!this.hasResult) {
            return null;
        }
        return new ProductCardCreatorResult(RArray.singleton([
            this.productInstanceCreator,
            this.quantity,
        ]).concatenated(this.crossSellingCreator.selectedItems.map(([creator, quantity, _]) => [
            creator,
            quantity,
        ])));
    }
    get isSplitPossible() {
        return this.productInstanceCreator.isSplitPossible;
    }
    updateCrossSellingProductTypes() {
        this.crossSellingCreator.setInputProductTypeIds(this.productTypeIds);
    }
    split() {
        this.productInstanceCreator.split();
        this.updateCrossSellingProductTypes();
    }
    removeFirstHalf() {
        this.productInstanceCreator.removeFirstHalf();
        this.updateCrossSellingProductTypes();
    }
    removeSecondHalf() {
        this.productInstanceCreator.removeSecondHalf();
        this.updateCrossSellingProductTypes();
    }
    setFirstHalfProductType(productType) {
        this.productInstanceCreator.setFirstHalfProductType(productType);
        this.updateCrossSellingProductTypes();
    }
    setSecondHalfProductType(productType) {
        this.productInstanceCreator.setSecondHalfProductType(productType);
        this.updateCrossSellingProductTypes();
    }
}
