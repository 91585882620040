import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import { useLayoutEffect, useRef } from "preact/hooks";
import { ModifierItemTypeId, RArray } from "restaumatic-client/src";
import AvailabilityDebugInfo from "../../components/AvailabilityDebugInfo";
import CreatorModifierSections from "./CreatorModifierSections";
import CreatorConfigMessage from "./CreatorConfigMessage";
import { t, translate } from "restaumatic-client";
function CreatorModifier(props) {
    const { fieldIdPrefix, modifierInstance } = props;
    const domRef = useRef(null);
    const fieldId = `${fieldIdPrefix}modifiers.${modifierInstance.typeId.toString()}`;
    const error = modifierInstance.error;
    const isRequired = modifierInstance.isRequired;
    const summary = modifierInstance.selectedItemsDescription;
    const description = modifierInstance.description;
    const toggleBtnText = modifierInstance.isEditorVisible
        ? translate(t.theme_defaults.creator.hide)
        : translate(t.menu.creator.actions.editModifier);
    const toggleEditor = () => {
        modifierInstance.toggleEditor();
    };
    useLayoutEffect(() => {
        const elem = domRef.current;
        if (elem !== null) {
            setupSemtreeCustomField(elem, modifierInstance);
        }
    });
    return (h("fieldset", { ref: domRef, "data-field-id": fieldId, "data-custom-field-type": "multiset", class: "m-form__group m-product-card__content" },
        h("div", { class: "row" },
            h("div", { class: `col-md-12 ${error === null ? "" : "has-error"}` },
                h("legend", { class: "h4" }, modifierInstance.name),
                description,
                h(AvailabilityDebugInfo, { fieldId: `${fieldId}.availability`, name: "ModifierInstance", availability: modifierInstance.availability }),
                h(CreatorConfigMessage, { error: error, isRequired: isRequired }),
                modifierInstance.isWrapping && (h("p", { class: "u-hyphens" },
                    summary,
                    modifierInstance.isTogglingEditorPossible && (h(Fragment, null,
                        " ",
                        h("button", { class: "btn-link", type: "button", onClick: toggleEditor }, toggleBtnText))))))),
        modifierInstance.isEditorVisible && (h(CreatorModifierSections, { modifierFieldId: fieldId, modifierInstance: modifierInstance }))));
}
/**
 * Install a _custom field_ on the element, representing the modifier item selections of
 * the ModifierInstance.
 *
 * See <https://docs.manca.ro/libs/js/semtree/index.html#custom-fields>.
 */
function setupSemtreeCustomField(elem, modifierInstance) {
    elem["getCustomFieldProperties"] = () => ({
        value: modifierInstance.domain.selectedItemIds.raw.map((x) => x.value),
        options: modifierInstance.sections
            .flatMap((modifierSection) => modifierSection.items)
            .map((item) => ({
            id: item.typeId.toString(),
            label: item.name,
            disabled: item.itemAvailability.isAvailable.isDefinitelyFalse,
        })),
    });
    elem["setCustomFieldValue"] = (selection) => {
        console.log("Selection: ", selection);
        modifierInstance.domain.selectedItemIds = new RArray(selection.map((x) => new ModifierItemTypeId(x)));
    };
}
export default observer(CreatorModifier);
