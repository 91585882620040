import { action, makeAutoObservable } from "mobx";
import { Availability, } from "../..";
export class DeliveryAddressStatus {
    constructor(params) {
        this._state = params.state;
        makeAutoObservable(this);
    }
    static createNotRequested() {
        return new DeliveryAddressStatus({
            state: { type: "NotRequested" },
        });
    }
    static createLoading() {
        return new DeliveryAddressStatus({
            state: { type: "Loading" },
        });
    }
    static createChangedAndNotValidatedYet() {
        return new DeliveryAddressStatus({
            state: { type: "ChangedAndNotValidatedYet" },
        });
    }
    static create(params) {
        const status = DeliveryAddressStatus.createNotRequested();
        // NOTICE: Set state in the background, don't wait for it
        void status.setState(params);
        return status;
    }
    static async asyncCreate(params) {
        const status = DeliveryAddressStatus.createNotRequested();
        await status.setState(params);
        return status;
    }
    setState(params) {
        const addressInvalid = params.streetAddress.availability.isAvailable.isDefinitelyFalse;
        if (addressInvalid) {
            this._state = { type: "InvalidAddress" };
            return Promise.resolve();
        }
        this._state = { type: "Loading" };
        return params.geocodingService
            .getDeliveryAddressStatus(params.restaurantId, params.streetAddress.clone())
            .then(action((state) => (this._state = state)))
            .catch(action((_) => (this._state = { type: "NetworkError" })));
    }
    get state() {
        return this._state;
    }
    get coordinates() {
        return "coordinates" in this._state ? this._state.coordinates : null;
    }
    get availability() {
        return this._state.type === "RestaurantDelivers"
            ? Availability.available()
            : Availability.unavailable("RestaurantDoesntDeliver");
    }
}
