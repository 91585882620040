import { RDate } from "../../../core";
export class RDay {
    constructor(rDate) {
        const day = rDate.raw.withPlainTime();
        this.raw = new RDate(day);
    }
    static fromRDate(rDate) {
        return new RDay(rDate);
    }
    static fromUTCString(strInUtc) {
        return new RDay(RDate.fromUtcTimeString(strInUtc, "UTC"));
    }
    static *range(from, to) {
        let current = from.toRDate();
        while (current.isSameOrBefore(to.toRDate())) {
            yield RDay.fromRDate(current);
            current = current.add(1, "days");
        }
    }
    get isToday() {
        return this.raw.isToday;
    }
    get isTomorrow() {
        return this.raw.isTomorrow;
    }
    get weekday() {
        return this.raw.weekday;
    }
    isBefore(other) {
        return this.raw.isBefore(other.raw);
    }
    isAfter(other) {
        return this.raw.isAfter(other.raw);
    }
    isSame(other) {
        return this.raw.eq(other.raw);
    }
    isSameOrAfter(other) {
        return this.raw.isSameOrAfter(other.raw);
    }
    isSameOrBefore(other) {
        return this.raw.isSameOrBefore(other.raw);
    }
    min(other) {
        return this.isBefore(other) ? this : other;
    }
    max(other) {
        return this.isAfter(other) ? this : other;
    }
    // NOTICE: Can be negative to subtract (or create new method to do this)
    addDays(daysNumber) {
        return RDay.fromRDate(this.raw.add(daysNumber, "days"));
    }
    toRDate() {
        return this.raw;
    }
    toString() {
        return this.raw.toString();
    }
    formatDDMM() {
        return this.raw.formatDDMM();
    }
}
