import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { RDate, RTimeZone } from "restaumatic-client";
import { t, translate } from "restaumatic-client";
function timeZoneInfo() {
    if (RTimeZone.isRestarantDifferentFromBrowserTimezone) {
        const timeZoneName = Skubacz.configuration.time_zone;
        const date = RDate.now(timeZoneName);
        return `${date.timeZoneLocale} (${date.formatHHmm()})`;
    }
    else {
        return null;
    }
}
export function RestaurantTimeZoneInfo() {
    const [restaurantTimeZoneInfo, setRestaurantTimeZoneInfo] = useState(timeZoneInfo());
    useEffect(() => {
        const timer = setInterval(() => {
            setRestaurantTimeZoneInfo(timeZoneInfo());
        }, 1000); // 1000 ms = 1 s
        return () => {
            clearInterval(timer);
        };
    }, []);
    if (restaurantTimeZoneInfo === null) {
        return null;
    }
    return (h("p", null,
        h("b", null, translate(t.orders.order_summary.time_zone_info)),
        h("br", null),
        restaurantTimeZoneInfo));
}
