export class ProductInstanceName {
    constructor(params) {
        this.productTypes = params.productTypes;
    }
    productInstanceName(productInstance) {
        return productInstance.parts.parts.raw
            .map((part) => {
            return this.productTypes.get(part.productType.id).name;
        })
            .join(" / ");
    }
}
