import { translate, t } from "../../I18n";
function toLowerCase(str) {
    return str.toLowerCase();
}
export function getDayName(date, abbreviation = false) {
    if (date.isToday) {
        return translate(t.date.today);
    }
    if (date.isTomorrow) {
        return translate(t.date.tomorrow);
    }
    if (abbreviation) {
        return translate(t.date.day.abbr_names[toLowerCase(date.weekday)]);
    }
    const translated = translate(t.date.day.names[toLowerCase(date.weekday)]);
    // Capitalize first letter due to translations missmatch (monday, Friday, etc.)
    return `${translated.charAt(0).toUpperCase()}${translated.slice(1)}`;
}
export function getAbbrMonthName(date) {
    return translate(t.date.month.abbr_names[toLowerCase(date.month)]);
}
export function getItemsFirstDate(dateItems) {
    const firstItem = dateItems[0];
    if (firstItem === undefined) {
        return null;
    }
    switch (firstItem.type) {
        case "Available":
        case "Conflicting":
            return firstItem.date;
        case "Disabled":
            return firstItem.from;
    }
}
export function getItemsLastDate(dateItems) {
    const lastItem = dateItems[dateItems.length - 1];
    if (lastItem === undefined) {
        return null;
    }
    switch (lastItem.type) {
        case "Available":
        case "Conflicting":
            return lastItem.date;
        case "Disabled":
            return lastItem.to;
    }
}
