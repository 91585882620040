import { h } from "preact";
import { observer } from "mobx-react";
import FieldError from "./FieldError";
import FieldLegend from "./FieldLegend";
function RadioField(props) {
    const { controller, options, name, label, required = true, dataFieldId, onChange, } = props;
    const hasError = (controller.isTouched && controller.isInvalid) ||
        (controller.isTouched && required && controller.value === null);
    const handleChange = (option) => {
        const { value } = option;
        controller.change(value);
        if (onChange !== undefined) {
            onChange();
        }
    };
    return (h("fieldset", { class: `radio_buttons form-group ${hasError ? "has-error" : ""}`, "data-field-id": dataFieldId, "data-field-type": "radioGroup" },
        h(FieldLegend, { isRequired: required }, label),
        options.map((option) => (h(RadioFieldOption, { name: name, isActive: controller.value === option.value, value: option.value, label: option.label, children: option.children, childrenCssClass: option.childrenCssClass, onChange: () => handleChange(option), onBlur: () => controller.blur(), key: option.value }))),
        hasError && h(FieldError, { errors: controller.errors })));
}
export const RadioFieldOption = observer((props) => {
    const { name, value, label, isActive, isDisabled = false, children, childrenCssClass, onChange, onBlur, } = props;
    const handleChange = () => {
        if (!isActive && !isDisabled) {
            onChange();
        }
    };
    return (h("div", { class: `m-radio m-radio--button ${isActive ? "is-active" : ""} ${isDisabled ? "is-disabled" : ""}`, "data-option-id": value },
        h("div", { class: "m_radio__body", onClick: handleChange },
            h("input", { type: "radio", name: name, id: value, value: value, checked: isActive, disabled: isDisabled, onChange: handleChange, onBlur: onBlur, class: "m-radio__input" }),
            h("i", { class: "m-radio__icon-state", "aria-hidden": "true" }),
            h("label", { class: "m-radio__label", for: value }, label)),
        children && (h("div", { class: `m-radio__children ${childrenCssClass !== null && childrenCssClass !== void 0 ? childrenCssClass : ""}` }, children))));
});
export default observer(RadioField);
