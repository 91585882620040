import { makeAutoObservable } from "mobx";
import { IdMap, RArray } from "../../collections";
import { Price } from "../../pricing/Price";
import { Availability } from "../Availability";
import { ParameterScope } from "../ParameterScope";
import { OrderLineReference, } from "../PromotionSolver";
import { promotionEffectPrice, } from "../PromotionType";
import { OrderLinePrice } from "../../pricing/OrderLinePrice";
import { OrderLineReferencePrice } from "../../pricing/OrderLineReferencePrice";
export class ProductPromotionInstance {
    constructor(params) {
        this.kind = "Product";
        this.id = params.id;
        this.promotionType = params.promotionType;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this._active = params.active;
        this.discountSource = params.discountSource;
        this.discountTarget = params.discountTarget;
        this._freebie = params.freebie;
        this.scope = ParameterScope.Uninitialized;
        this.excludedInstances = IdMap.empty();
        makeAutoObservable(this);
    }
    static fromStorageData(params) {
        const { promotionType, id, storageData, purchaseOrderPayload } = params;
        if (promotionType.scope.kind !== "Product") {
            return null;
        }
        const discountSource = storageData.discountSource.map((referenceStorageData) => OrderLineReference.fromStorageData({
            purchaseOrderPayload,
            storageData: referenceStorageData,
        }));
        if (!discountSource.every((el) => el !== null)) {
            return null;
        }
        const discountTarget = OrderLineReference.fromStorageData({
            purchaseOrderPayload,
            storageData: storageData.discountTarget,
        });
        if (discountTarget === null) {
            return null;
        }
        return new ProductPromotionInstance({
            id,
            promotionType,
            purchaseOrderPayload,
            active: storageData.active,
            discountSource: new RArray(discountSource),
            discountTarget,
            freebie: params.freebie,
        });
    }
    setScope(scope) {
        this.scope = scope;
    }
    setExcludedInstances(excluded) {
        this.excludedInstances = excluded;
    }
    get active() {
        return this._active;
    }
    setActive(active) {
        this._active = active;
        if (active) {
            this.excludedInstances.objects.forEach((excludedInstance) => excludedInstance.setActive(false));
        }
    }
    get freebie() {
        return this._freebie;
    }
    setFreebie(freebie) {
        this._freebie = freebie;
    }
    get value() {
        if (!this.active) {
            return Price.free();
        }
        return promotionEffectPrice(this.promotionType.effect, OrderLineReferencePrice.price(this.discountTarget), this.freebie);
    }
    get checkoutData() {
        const value = this.value.finalValue;
        return {
            promotionId: this.promotionType.id.value,
            promotionalItemId: this.id.value,
            source: this.discountSource.map((orderLineReference) => orderLineReference.checkoutData).raw,
            input: this.freebie === null
                ? { tag: "NoInput" }
                : { tag: "FreeProductInput", selection: this.freebie.name },
            active: this.active,
            assignment: this.discountTarget.checkoutData,
            value: value ? value.asNumber : null,
        };
    }
    get storageData() {
        return {
            kind: "ProductPromotionInstance",
            promotionTypeId: this.promotionType.id.value,
            active: this.active,
            discountSource: this.discountSource.raw.map((discountSource) => discountSource.storageData),
            discountTarget: this.discountTarget.storageData,
            freebie: this.freebie === null ? null : this.freebie.storageData,
        };
    }
    get availability() {
        var _a, _b;
        const { orderLines } = this.purchaseOrderPayload;
        const sourceOrderLines = this.discountSource.raw.map((orderLineReference) => orderLines.find(orderLineReference.orderLineIndex));
        const targetOrderLine = orderLines.find(this.discountTarget.orderLineIndex);
        const productInstances = [
            ...sourceOrderLines.map((orderLine) => { var _a; return (_a = orderLine === null || orderLine === void 0 ? void 0 : orderLine.productInstance) !== null && _a !== void 0 ? _a : null; }),
            (_a = targetOrderLine === null || targetOrderLine === void 0 ? void 0 : targetOrderLine.productInstance) !== null && _a !== void 0 ? _a : null,
        ];
        const selector = this.promotionType.scope
            .selector;
        return Availability.composite([
            Availability.ofPromotionType(this.promotionType, this.scope),
            Availability.dependent("DiscountSource", `PromotionInstance${this.id.toString()}`, Availability.composite(sourceOrderLines.map((orderLine) => {
                var _a;
                return (_a = orderLine === null || orderLine === void 0 ? void 0 : orderLine.availability) !== null && _a !== void 0 ? _a : Availability.unavailable("InvalidSourceReference");
            }))),
            Availability.dependent("DiscountTarget", `PromotionInstance${this.id.toString()}`, (_b = targetOrderLine === null || targetOrderLine === void 0 ? void 0 : targetOrderLine.availability) !== null && _b !== void 0 ? _b : Availability.unavailable("InvalidTargetReference")),
            Availability.boolean({
                // NOTICE Assignment is correct only when discountTarget is cheaper than all of discountSource elements
                InvalidAssignment: sourceOrderLines.some((sourceOrderLine) => sourceOrderLine === null ||
                    targetOrderLine === null ||
                    OrderLinePrice.unitPrice(sourceOrderLine).compare(OrderLinePrice.unitPrice(targetOrderLine)) < 0),
                UnmetSelector: productInstances.some((productInstance) => productInstance === null || !selector.test(productInstance)),
            }),
        ]);
    }
}
