import { observer } from "mobx-react";
import { h } from "preact";
import { makeImgUrl, setQueryParams } from "../utils/URL";
import { translate, t, } from "restaumatic-client";
import ShowMore from "./ShowMore";
import NumericStepper, { NumericStepperButton } from "./NumericStepper";
import AllergensPopover from "./AllergensPopover";
const PLACEHOLDER_IMAGE_URN = "/uploads/media_library/3759b3df5982ce78756f289321cb79d3.png";
const IMAGE_PARAMS = {
    auto: "compress",
    crop: "focalpoint",
    fit: "crop",
    "max-h": "200",
    "max-w": "400",
};
function CrossSellingItem(props) {
    var _a;
    const { item, className = "" } = props;
    const imageUrn = (_a = item.image) !== null && _a !== void 0 ? _a : PLACEHOLDER_IMAGE_URN;
    const imageSrc = setQueryParams(IMAGE_PARAMS, makeImgUrl(imageUrn));
    const hasAllergens = item.allergens.length > 0;
    return (h("article", { class: `m-crossup__item ${className}` },
        h("div", { class: `m-crossup__item-content ${item.behaviour.isActive ? "is-active" : ""}` },
            h("picture", { class: "m-crossup__item-picture" },
                h("img", { class: "m-crossup__item-img", loading: "lazy", src: imageSrc, alt: item.name })),
            h("div", { class: "u-flex-grow" },
                h("h4", { class: "u-my0 u-font-size-inherit" },
                    h("span", { class: "m-crossup__item-title" }, item.name),
                    " ",
                    hasAllergens && h(AllergensPopover, { allergens: item.allergens })),
                h(ShowMore, { containerClass: "d-flex flex-column", textClass: "m-crossup__item-desc" }, item.description),
                h("footer", { class: "d-flex justify-content-between align-items-center" },
                    h("div", { class: "m-crossup__price" }, item.price),
                    h(CrossSellingItemCounter, { behaviour: item.behaviour }))))));
}
const CrossSellingItemCounter = observer(({ behaviour }) => {
    const size = "large";
    const addLabel = translate(t.restaurants.menu.add);
    const addClass = "m-crossup__counter-btn--add";
    switch (behaviour.type) {
        case "Cart":
            return (h(NumericStepperButton, { type: "increment", onClick: () => behaviour.add(), ariaLabel: addLabel, className: addClass, size: size }));
        case "ProductCard":
            return (h(NumericStepper, { type: "dynamic", quantity: behaviour.quantity, canDecrement: behaviour.canDecrement, onDecrement: () => behaviour.decrement(), decrementAriaLabel: translate(t.restaurants.menu.remove), onIncrement: () => behaviour.increment(), incrementAriaLabel: addLabel, incrementClass: addClass, size: size }));
    }
});
export default observer(CrossSellingItem);
