import { action, makeAutoObservable, runInAction } from "mobx";
import { DeliveryAddress, DeliveryAddressStatus, } from "../ordering";
const VALID_ADDRESS_STATUS_TYPES = [
    "ChooseRestaurant",
    "ChangeRestaurant",
    "RestaurantDoesntDeliverTo",
    "RestaurantDelivers",
];
export class PDeliveryAddress {
    constructor(params) {
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.restaurantId = params.restaurantId;
        this.geocodingService = params.geocodingService;
        this.streetAddress =
            params.fulfillmentInstructions.deliveryAddress.streetAddress.clone();
        this.apartmentInfo =
            params.fulfillmentInstructions.deliveryAddress.apartmentInfo.clone();
        this._status = params.fulfillmentInstructions.deliveryAddress.status;
        makeAutoObservable(this);
    }
    static create(params) {
        // TODO: QrWaiter strategy on thj top level
        if (params.fulfillmentInstructions.type === "QrWaiter") {
            return null;
        }
        return new PDeliveryAddress({
            fulfillmentInstructions: params.fulfillmentInstructions,
            restaurantId: params.restaurantId,
            geocodingService: params.geocodingService,
        });
    }
    get availability() {
        return this.streetAddress.availability;
    }
    get status() {
        return this._status;
    }
    async validate() {
        const domainStreetAddress = this.fulfillmentInstructions.deliveryAddress.streetAddress;
        if (!this.streetAddress.eq(domainStreetAddress) ||
            // TODO: (B2C-256)
            this._status.state.type === "ChangedAndNotValidatedYet") {
            await DeliveryAddressStatus.asyncCreate({
                restaurantId: this.restaurantId,
                streetAddress: this.streetAddress,
                geocodingService: this.geocodingService,
            }).then(action((status) => (this._status = status)));
        }
        return VALID_ADDRESS_STATUS_TYPES.includes(this._status.state.type);
    }
    /**
     * Saves a new value only if the address is valid (for modal form). Returns the status of saving.
     */
    async validateAndSave() {
        const isValid = await this.validate();
        if (isValid) {
            this.saveInDomain();
        }
        return isValid;
    }
    /**
     * Saves even invalid value instantly without validation (for Cart & Checkout inline forms)
     * NOTICE: You should validate by `forceSave()` on the input blur event
     * TODO: (B2C-256) remove it when address will be saved only in AddressModal
     */
    saveUnvalidated() {
        this._status = DeliveryAddressStatus.createChangedAndNotValidatedYet();
        this.saveInDomain();
    }
    /**
     * Save the address with proper status, even if not valid
     * TODO: (B2C-256) remove it when address will be saved only in AddressModal
     */
    async validateAndForceSave() {
        const isValid = await this.validate();
        this.saveInDomain();
        return isValid;
    }
    saveInDomain() {
        runInAction(() => {
            this.fulfillmentInstructions.deliveryAddress = new DeliveryAddress({
                streetAddress: this.streetAddress.clone(),
                apartmentInfo: this.apartmentInfo.clone(),
                status: this._status,
            });
        });
    }
}
