import { makeImmutable } from "../../core";
export class NextOrderingTime {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.scope = params.scope;
        makeImmutable(this);
    }
    get nextOrderAvailableAt() {
        const date = this.findNextOrderingHour();
        if (date === null) {
            return null;
        }
        else if (date.isToday) {
            return {
                type: "today",
                hour: date.formatHHmm(),
            };
        }
        else if (date.isTomorrow) {
            return {
                type: "tomorrow",
                hour: date.formatHHmm(),
            };
        }
        else {
            return {
                type: "later",
                date: date.formatDDMM(),
            };
        }
    }
    findNextOrderingHour() {
        for (let day = 0; day < 70; day++) {
            const date = day == 0
                ? this.scope.now
                : this.scope.now.add(day, "days").startOfDay();
            const nextEvent = this.orderingHours.nextEventAtDate(date);
            if (nextEvent.type === "OrderingEnds") {
                return null;
            }
            else {
                if (nextEvent.at !== null) {
                    return nextEvent.at;
                }
            }
        }
        return null;
    }
}
