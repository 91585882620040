import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import AvailabilityDebugInfo from "../../components/AvailabilityDebugInfo";
import NumericStepper from "../../components/NumericStepper";
import { makeImgUrl } from "../../utils/URL";
import { t, translate } from "restaumatic-client";
function CreatorModifierItem(props) {
    const { modifierFieldId, modifierItemInstance } = props;
    const fieldId = `${modifierFieldId}.${modifierItemInstance.typeId.toString()}`;
    switch (modifierItemInstance.behaviour) {
        case "SingleChoice":
            return (h(CreatorModifierSingleChoiceItem, { fieldId: fieldId, modifierItemInstance: modifierItemInstance }));
        case "MultipleChoice":
            return (h(CreatorModifierMultipleChoiceItem, { fieldId: fieldId, modifierItemInstance: modifierItemInstance }));
        // NOTICE: Wrapping (pizza grid) is replaced by numeric type
        // (there are some differences in css classes only)
        case "Wrapping":
        case "Numeric":
            return (h(CreatorModifierNumericItem, { fieldId: fieldId, modifierItemInstance: modifierItemInstance }));
    }
}
const CreatorModifierSingleChoiceItem = observer((props) => {
    const { fieldId, modifierItemInstance } = props;
    const isChecked = modifierItemInstance.isChecked;
    const handleChange = (event) => {
        const checked = event.target.checked;
        if (checked) {
            modifierItemInstance.select();
        }
    };
    return (h("label", { "data-field-id": `${fieldId}.selected`, class: `m-radio m-radio--option ${isChecked ? "is-selected" : ""}` },
        h("input", { checked: isChecked, type: "radio", class: "m-radio__input", name: modifierItemInstance.instanceId.toString(), value: modifierItemInstance.typeId.toString(), onChange: handleChange }),
        h("span", { class: "m-radio__icon-state", "aria-hidden": "true" }),
        h(CreatorModifierItemPhoto, { photoUrn: modifierItemInstance.photo }),
        h("span", { class: "topping__name" },
            h(CreatorModifierItemName, { fieldId: fieldId, modifierItemInstance: modifierItemInstance }))));
});
const CreatorModifierMultipleChoiceItem = observer(function (props) {
    const { fieldId, modifierItemInstance } = props;
    const isChecked = modifierItemInstance.isSelected;
    const handleChange = (event) => {
        const checked = event.target.checked;
        modifierItemInstance.setSelected(checked);
    };
    return (h("label", { "data-field-id": `${fieldId}.selected`, class: `m-checkbox m-checkbox--option ${isChecked ? "is-selected" : ""}` },
        h("input", { checked: isChecked, type: "checkbox", class: "m-checkbox__input", name: modifierItemInstance.instanceId.toString(), value: modifierItemInstance.typeId.toString(), onChange: handleChange }),
        h("span", { class: "m-checkbox__icon-state", "aria-hidden": "true" }),
        h(CreatorModifierItemPhoto, { photoUrn: modifierItemInstance.photo }),
        h("span", { class: "topping__name" },
            h(CreatorModifierItemName, { fieldId: fieldId, modifierItemInstance: modifierItemInstance }))));
});
const CreatorModifierNumericItem = observer((props) => {
    const { fieldId, modifierItemInstance } = props;
    const isWrapping = modifierItemInstance.behaviour === "Wrapping";
    const quantity = modifierItemInstance.itemQuantity;
    const price = modifierItemInstance.unitPrice;
    const isSelected = modifierItemInstance.isSelected;
    const canDecrement = modifierItemInstance.canDecrement;
    const canIncrement = modifierItemInstance.canIncrement;
    const handleDecrement = () => modifierItemInstance.decrement();
    const handleIncrement = () => modifierItemInstance.increment();
    return (h("div", { class: `m-modifier-item ${isWrapping ? "m-modifier-item--wrapping" : ""} ${isSelected ? "is-selected" : ""}`, "data-field-id": `${fieldId}.selected` },
        h("div", { class: "m-modifier-item__stepper" },
            h(NumericStepper, { quantity: quantity, canDecrement: canDecrement || false, canIncrement: canIncrement || false, decrementAriaLabel: translate(t.theme_defaults.creator.stepper.decrease, {
                    name: modifierItemInstance.name,
                    from: quantity.toString(),
                    to: Math.max(quantity - 1, 0).toString(),
                    price: `-${price}`,
                }), incrementAriaLabel: translate(t.theme_defaults.creator.stepper.increase, {
                    name: modifierItemInstance.name,
                    from: quantity.toString(),
                    to: (quantity + 1).toString(),
                    price: price,
                }), decrementTestId: `${modifierItemInstance.typeId.toString()}.decrease`, incrementTestId: `${modifierItemInstance.typeId.toString()}.increase`, onDecrement: handleDecrement, onIncrement: handleIncrement })),
        h("div", { class: isWrapping ? "topping__name" : "m-modifier-item__name" },
            h(CreatorModifierItemPhoto, { photoUrn: modifierItemInstance.photo }),
            h("span", { class: isWrapping ? "" : "topping__name" },
                h(CreatorModifierItemName, { fieldId: fieldId, modifierItemInstance: modifierItemInstance })))));
});
function CreatorModifierItemPhoto(props) {
    const { photoUrn } = props;
    const photoUrl = photoUrn === null ? null : makeImgUrl(photoUrn);
    if (photoUrl === null) {
        return null;
    }
    return (h("span", { class: "topping__icon", style: `background-image: url(${photoUrl})` }));
}
const CreatorModifierItemName = observer((props) => {
    const { fieldId, modifierItemInstance } = props;
    const name = modifierItemInstance.name;
    const price = modifierItemInstance.unitPrice;
    const availability = modifierItemInstance.itemAvailability;
    return (h(Fragment, null,
        h("span", null,
            name,
            " "),
        h("span", { class: "topping__price" }, price),
        h(AvailabilityDebugInfo, { fieldId: `${fieldId}.availability`, name: "ModifierItem", availability: availability })));
});
export default observer(CreatorModifierItem);
