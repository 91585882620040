import { h } from "preact";
import { observer } from "mobx-react";
import { translate, t } from "restaumatic-client";
import PreactPopover from "./PreactPopover";
var AllergenId;
(function (AllergenId) {
    AllergenId[AllergenId["Gluten"] = 1] = "Gluten";
    AllergenId[AllergenId["Crustaceans"] = 2] = "Crustaceans";
    AllergenId[AllergenId["Eggs"] = 3] = "Eggs";
    AllergenId[AllergenId["Fish"] = 4] = "Fish";
    AllergenId[AllergenId["Peanuts"] = 5] = "Peanuts";
    AllergenId[AllergenId["Soybeans"] = 6] = "Soybeans";
    AllergenId[AllergenId["Milk"] = 7] = "Milk";
    AllergenId[AllergenId["Nuts"] = 8] = "Nuts";
    AllergenId[AllergenId["Celery"] = 9] = "Celery";
    AllergenId[AllergenId["Mustard"] = 10] = "Mustard";
    AllergenId[AllergenId["Sesame"] = 11] = "Sesame";
    AllergenId[AllergenId["SulphurDioxide"] = 12] = "SulphurDioxide";
    AllergenId[AllergenId["Lupin"] = 13] = "Lupin";
    AllergenId[AllergenId["Molluscs"] = 14] = "Molluscs";
})(AllergenId || (AllergenId = {}));
function AllergensPopover(props) {
    const { allergens } = props;
    // Array of ids sorted by allergen number
    const ids = allergens
        .map((allergen) => AllergenId[allergen])
        .sort((a, b) => a - b);
    // Short info is only allergen numbers
    const shortInfo = ids.join(", ");
    // Details are allergen numbers and names
    const details = ids
        .map((id) => {
        const name = translate(t.theme_defaults.menu.allergens.names[AllergenId[id]]);
        // eslint-disable-next-line no-irregular-whitespace
        return `${id}. ${name}`;
    })
        .join(", ");
    return (h("article", { class: "u-inline-block u-mx1 u-va-top u-font-size-small js-popover-container" },
        h(PreactPopover, { title: translate(t.theme_defaults.menu.allergens.label), content: h("span", null, details), trigger: "focus", className: "u-btn-unstyled btn-wrap text-muted text-left" }, shortInfo)));
}
export default observer(AllergensPopover);
