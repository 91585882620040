import { makeAutoObservable } from "mobx";
import { IdMap, RArray } from "../../collections";
import { ProductPromotionInstance, PromotionInstanceId, WholeOrderPromotionInstance, } from "../PromotionInstance";
import { CardinalityConstraint } from "./CardinalityConstraint";
import { PromotionSolverAlgorithm } from "./PromotionSolverAlgorithm";
import { PromotionSolverVariables } from "./PromotionSolverVariables";
import { OrderLineReferencePrice } from "../../pricing/OrderLineReferencePrice";
const SOLVER_EXPLORATION_LIMIT = 10000;
export class PromotionSolver {
    constructor(variableAssignment) {
        this.promotionInstanceIdSequence = 0;
        this.variableCount = variableAssignment.variableCount;
        this.variables = variableAssignment.byVariable;
        this.variablesByPromotion = variableAssignment.byPromotion;
        this.purchaseOrderPayload = variableAssignment.purchaseOrderPayload;
        makeAutoObservable(this);
    }
    cheaperVariable(a, b) {
        return OrderLineReferencePrice.price(this.variables.get(a)[1]).compare(OrderLineReferencePrice.price(this.variables.get(b)[1]));
    }
    get cardinalityConstraints() {
        return this.variablesByPromotion.mapOptional(([promotionType, variables]) => {
            const { scope: promotionScope } = promotionType;
            switch (promotionScope.kind) {
                case "Product": {
                    const orderedVariables = variables.sorted((a, b) => this.cheaperVariable(a, b));
                    return CardinalityConstraint.empty(new Set(orderedVariables), promotionScope.requiredItems.value, promotionScope.repeatable);
                }
                case "WholeOrder":
                    return CardinalityConstraint.empty(new Set(variables), 1, false);
            }
        });
    }
    get exclusionConstraints() {
        const exclusions = new Array(this.variableCount)
            .fill(undefined)
            .map(() => []);
        this.variables.forEach(([promotionTypeA, orderLineReferenceA], variableA) => {
            this.variables.forEach(([promotionTypeB, orderLineReferenceB], variableB) => {
                // NOTICE variable canot exclude itself
                if (variableA === variableB)
                    return;
                // NOTICE variables belonging to the same promotion never exclude
                if (promotionTypeA.id.eq(promotionTypeB.id))
                    return;
                if (orderLineReferenceA && orderLineReferenceB) {
                    // NOTICE promotions only exclude on the same orderLineReferences
                    if (!orderLineReferenceA.eq(orderLineReferenceB))
                        return;
                }
                if (!promotionTypeA.combinesWith.includes(promotionTypeB.id)) {
                    exclusions[variableA].push(variableB);
                }
            });
        });
        return exclusions;
    }
    get equivalenceClasses() {
        let currentEquivalenceClass = 0;
        const equivalenceMapping = new Map();
        const res = this.variables.raw.map(([promotionType, orderLineReference]) => {
            var _a;
            const equivalenceKey = `${promotionType.id.toString()},${(_a = orderLineReference === null || orderLineReference === void 0 ? void 0 : orderLineReference.orderLineIndex) !== null && _a !== void 0 ? _a : "order"}`;
            let equivalenceClass = equivalenceMapping.get(equivalenceKey);
            if (equivalenceClass === undefined) {
                equivalenceMapping.set(equivalenceKey, currentEquivalenceClass);
                equivalenceClass = currentEquivalenceClass;
                currentEquivalenceClass += 1;
            }
            return equivalenceClass;
        });
        return res;
    }
    get nonAutoEnabledVariables() {
        return this.variablesByPromotion.flatMap(([promotionType, assigmentVariables]) => {
            return promotionType.autoselect
                ? RArray.empty()
                : assigmentVariables;
        });
    }
    nextPromotionInstanceId() {
        return new PromotionInstanceId(this.promotionInstanceIdSequence++);
    }
    get promotionInstances() {
        if (this.variableCount < 1) {
            return IdMap.empty();
        }
        const variables = PromotionSolverVariables.empty({
            variableCount: this.variableCount,
            cardinalityConstraints: this.cardinalityConstraints,
            exclusionConstraints: this.exclusionConstraints,
            equivalenceClasses: this.equivalenceClasses,
        });
        const solver = new PromotionSolverAlgorithm({
            limit: SOLVER_EXPLORATION_LIMIT,
            variables: variables.exclude(this.nonAutoEnabledVariables.raw),
        });
        const solution = solver.solve();
        if (!solution) {
            return IdMap.empty();
        }
        const promotionInstances = this.variablesByPromotion.flatMap(([promotionType, assigmentVariables]) => {
            const active = assigmentVariables.some((variable) => solution.variables.at(variable));
            const solutionAssignment = active
                ? assigmentVariables.filtered((variable) => solution.variables.at(variable))
                : assigmentVariables;
            if (solutionAssignment.isEmpty) {
                return RArray.empty();
            }
            switch (promotionType.scope.kind) {
                case "Product": {
                    const requiredItems = promotionType.scope.requiredItems.value;
                    let distribution = this.distribute(solutionAssignment, requiredItems);
                    // NOTICE when solver returns solutionAssignment for inactive promotion it contains all variables and not just enabled ones
                    // this causes default distribution algorithm to create multiple instances even if promotion was is repeatable
                    if (!promotionType.scope.repeatable && distribution.size > 1) {
                        distribution = RArray.singleton(distribution.get(0));
                    }
                    return distribution.map(({ discountSource, discountTarget }) => new ProductPromotionInstance({
                        id: this.nextPromotionInstanceId(),
                        promotionType,
                        purchaseOrderPayload: this.purchaseOrderPayload,
                        active,
                        discountSource,
                        discountTarget,
                        freebie: promotionType.defaultFreebie,
                    }));
                }
                case "WholeOrder":
                    return RArray.singleton(new WholeOrderPromotionInstance({
                        id: this.nextPromotionInstanceId(),
                        promotionType,
                        purchaseOrderPayload: this.purchaseOrderPayload,
                        active,
                        freebie: promotionType.defaultFreebie,
                    }));
            }
        });
        return IdMap.fromIterable(promotionInstances);
    }
    distribute(solutionAssignment, requiredItems) {
        const assignmentOrderLineReferences = solutionAssignment
            .map((variable) => ({
            variable,
            reference: this.variables.get(variable)[1],
        }))
            .sorted((a, b) => OrderLineReferencePrice.price(a.reference).compare(OrderLineReferencePrice.price(b.reference)));
        const [targets, allSources] = assignmentOrderLineReferences.sliceAt(assignmentOrderLineReferences.size / requiredItems);
        const sources = requiredItems > 1
            ? allSources.chunks(requiredItems - 1)
            : RArray.empty();
        return targets.map((discountTarget, index) => {
            var _a;
            const source = (_a = sources.find(index)) !== null && _a !== void 0 ? _a : RArray.empty();
            return {
                discountSource: source.map((orderLineReferenceWithVariable) => orderLineReferenceWithVariable.reference),
                discountTarget: discountTarget.reference,
            };
        });
    }
}
