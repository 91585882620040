import BSModal from "bootstrap/js/dist/modal";
import { observer } from "mobx-react";
import { h } from "preact";
import { createPortal, useEffect, useMemo, useState } from "preact/compat";
import { t, translate } from "restaumatic-client";
function PreactModal(props) {
    const { id, dataFieldId, modalClass, onShow, onShown, onHidden, children } = props;
    // Prevent rendering content when it's not visible (hidden).
    const [isContentRendered, setIsContentRendered] = useState(false);
    // Prepare modal container element
    const modalElement = useMemo(() => {
        const element = document.createElement("div");
        element.id = id;
        element.classList.add("modal", "fade");
        element.tabIndex = -1;
        return element;
    }, [id]);
    // Add or remove modal container to the DOM
    useEffect(() => {
        document.body.appendChild(modalElement);
        return () => {
            const modalInstance = BSModal.getOrCreateInstance(modalElement);
            modalInstance.hide();
            document.body.removeChild(modalElement);
        };
    }, [modalElement]);
    // Attach & detach modal element & it's events
    useEffect(() => {
        const handleShow = () => {
            setIsContentRendered(true);
            if (onShow)
                onShow();
        };
        const handleShown = () => {
            if (onShown)
                onShown();
        };
        const handleHidden = () => {
            setIsContentRendered(false);
            if (onHidden)
                onHidden();
        };
        modalElement.addEventListener("show.bs.modal", handleShow);
        modalElement.addEventListener("shown.bs.modal", handleShown);
        modalElement.addEventListener("hidden.bs.modal", handleHidden);
        return () => {
            modalElement.removeEventListener("show.bs.modal", handleShow);
            modalElement.removeEventListener("shown.bs.modal", handleShown);
            modalElement.removeEventListener("hidden.bs.modal", handleHidden);
        };
    }, [modalElement, onShow, onShown, onHidden]);
    return createPortal(h("div", { class: `modal-dialog ${modalClass !== null && modalClass !== void 0 ? modalClass : ""}`, "data-field-id": dataFieldId, role: "document" },
        h("div", { class: "modal-content" }, isContentRendered ? children : null)), modalElement);
}
export const ModalHeader = (props) => {
    const { title, titleId, closeLabel = translate(t.generic.close), closeGaAction, closeFieldId, children, } = props;
    return (h("div", { class: "modal-header" },
        h("button", { type: "button", "data-bs-dismiss": "modal", "aria-label": closeLabel, "data-ga-action": closeGaAction, "data-field-id": closeFieldId, class: "modal-close" },
            h("span", { "aria-hidden": "true", class: "icon-cancel" })),
        h("div", { class: "modal-title-wrapper" },
            h("h4", { id: titleId, class: "modal-title" }, title),
            " ",
            children)));
};
export default observer(PreactModal);
