import { h } from "preact";
import { observer } from "mobx-react";
import { translate, t } from "restaumatic-client";
function CreatorConfigMessage(props) {
    var _a;
    const { error, isRequired, fieldId } = props;
    const fieldProps = fieldId === undefined
        ? {}
        : { "data-field-id": fieldId, "data-field-type": "info" };
    const errorMessage = (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : null;
    const requiredMessage = isRequired
        ? translate(t._common.form.info.required)
        : null;
    const message = errorMessage !== null && errorMessage !== void 0 ? errorMessage : requiredMessage;
    if (message === null) {
        return null;
    }
    return (h("p", { class: "help-block", ...fieldProps }, message));
}
export default observer(CreatorConfigMessage);
