import { POrderSummaryCompleted } from "./POrderSummaryCompleted";
import { RDate } from "../../core";
import { POrderSummaryCancelled } from "./POrderSummaryCancelled";
export function fromResponseState(params) {
    const { state, orderInfo } = params.orderSummaryResponse;
    const { createdAt, requestedFulfillmentTime, timezone } = orderInfo;
    const restaurantAddress = {
        name: params.orderSummaryResponse.restaurantName,
        street: params.orderSummaryResponse.restaurantAddressStreet,
        postCode: params.orderSummaryResponse.restaurantAddressPostCode,
        city: params.orderSummaryResponse.restaurantAddressCity,
    };
    const fulfillmentMethodType = orderInfo.fulfillmentMethodType === "Takeaway"
        ? {
            type: "Takeaway",
            restaurantAddress,
        }
        : {
            type: orderInfo.fulfillmentMethodType,
        };
    const isPreorder = getIsPreorder(createdAt, requestedFulfillmentTime, timezone);
    switch (state.tag) {
        case "Placed":
            return { type: "Loading", isFinal: false };
        case "WaitingForPayment":
            return { type: "WaitingForPayment", isFinal: false };
        case "WaitingForDispatch":
            return {
                type: "WaitingForDispatch",
                openAt: state.openAt,
                nextAvailableDay: state.nextAvailableDay !== null
                    ? RDate.fromUtcTimeString(state.nextAvailableDay, timezone)
                    : null,
                isPreorder,
                isFinal: false,
            };
        case "WaitingForConfirmation": {
            const date = requestedFulfillmentTime !== null
                ? RDate.fromUtcTimeString(requestedFulfillmentTime, timezone)
                : null;
            return {
                type: "WaitingForConfirmation",
                fulfillmentMethodType,
                isPreorder,
                fulfillmentTime: date
                    ? {
                        date: date.formatDDMMYYYY(),
                        hour: date.formatHHmm(),
                    }
                    : null,
                isFinal: false,
            };
        }
        case "Completed":
            return new POrderSummaryCompleted({
                restaurantAddress,
                completedAt: RDate.fromUtcTimeString(state.contents[1], state.contents[0]),
                isPreorder,
                fulfillmentMethodType,
                restaurantPhones: params.orderSummaryResponse.restaurantPhones,
                now: () => RDate.now(state.contents[0]),
            });
        case "Cancelled":
            return new POrderSummaryCancelled({
                reason: state.contents,
                isOnlinePayment: orderInfo.onlinePayment,
                restaurantPhones: params.orderSummaryResponse.restaurantPhones,
                retry: params.retry,
            });
    }
}
function getIsPreorder(createdAt, requestedFulfillmentTime, timezone) {
    if (requestedFulfillmentTime === null) {
        return false;
    }
    const orderingDate = RDate.fromUtcTimeString(createdAt, timezone);
    const fulfullmentDate = RDate.fromUtcTimeString(requestedFulfillmentTime, timezone);
    return !orderingDate.isSameDate(fulfullmentDate);
}
