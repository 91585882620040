import { makeAutoObservable, runInAction } from "mobx";
import { optionalEq } from "../../core";
import { Availability } from "../Availability";
/**
 * A concrete selection of a CustomParameterChoice from a CustomParameterType by the user.
 *
 * For example: the user chooses "20cm" for the "size" parameter.
 */
export class CustomParameterInstance {
    constructor(params) {
        // Note: assigned in constructor via choose
        this._selectedChoice = null;
        this.customParameterType = params.customParameterType;
        if (params.selectedChoice) {
            this.choose(params.selectedChoice);
        }
        makeAutoObservable(this);
    }
    eq(other) {
        return (this.typeId.eq(other.typeId) &&
            optionalEq(this.selectedChoice, other.selectedChoice));
    }
    static create(customParameterType, selectedChoice) {
        return new CustomParameterInstance({
            customParameterType,
            selectedChoice,
        });
    }
    static createWithDefaultChoice(customParameterType) {
        return CustomParameterInstance.create(customParameterType, customParameterType.defaultChoice);
    }
    static fromStorageData({ menu, customParameterTypeId, storageData, }) {
        const customParameterType = menu.customParameterTypes.rawFind(customParameterTypeId);
        if (customParameterType === null) {
            return null;
        }
        const selectedChoice = customParameterType.choiceIds.rawFind(storageData.selectedChoice);
        return new CustomParameterInstance({
            customParameterType,
            selectedChoice,
        });
    }
    choiceAvailability(parameterChoiceId) {
        return Availability.ofCustomParameterChoice(this.customParameterType.choice(parameterChoiceId));
    }
    get availability() {
        if (!this.selectedChoice) {
            return Availability.unavailable("Required");
        }
        return Availability.dependent("CustomParameterChoice", "", this.choiceAvailability(this.selectedChoice));
    }
    get typeId() {
        return this.customParameterType.id;
    }
    /**
     * Select one of the choices in this parameter
     */
    choose(choiceId) {
        if (!this.customParameterType.includesChoice(choiceId)) {
            throw new Error(`CustomParameterChoiceId ${choiceId.toString()} is not supported`);
        }
        runInAction(() => {
            this._selectedChoice = choiceId;
        });
    }
    get selectedChoice() {
        return this._selectedChoice;
    }
    clone() {
        return new CustomParameterInstance({
            customParameterType: this.customParameterType,
            selectedChoice: this._selectedChoice,
        });
    }
    get storageData() {
        if (this.selectedChoice === null) {
            return null;
        }
        return {
            selectedChoice: this.selectedChoice.value,
        };
    }
}
