/**
 * Simple debounce function.
 *
 * @param {function} callback
 * @param {number} wait
 * @returns
 */
export default function (callback, wait = 100) {
  let timeout;

  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      callback(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
