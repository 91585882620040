import { h } from "preact";
import CreatorActions from "./CreatorActions";
import CreatorPhoto from "./CreatorPhoto";
function CreatorHeader(props) {
    const { photoUrn } = props;
    return (h("div", { class: "m-product-card__header", "data-field-id": "Creator.header" },
        photoUrn !== null && h(CreatorPhoto, { photoUrn: photoUrn }),
        h(CreatorActions, null)));
}
export default CreatorHeader;
