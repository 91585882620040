import { IdMap, RMap, RMutableMap } from "../collections";
import { makeImmutable } from "../core";
import { PreorderSettings, ProductTypeGroup, ProductTypeGroupId, ProductTypeId, } from "../ordering";
export class ProductTypeGroupParser {
    constructor(params) {
        this.domainScheduleIds = params.domainScheduleIds;
        this.onlineOrderingSettings = params.onlineOrderingSettings;
        this.isPreorderEnabled = params.isPreorderEnabled;
        this.timezone = params.timezone;
        this.orderingHours = params.orderingHours;
        this.sectionVisibilityService = params.sectionVisibilityService;
        makeImmutable(this);
    }
    parse(activeSections) {
        var _a;
        const productTypeGroups = RMutableMap.empty();
        for (const [sectionId, section] of activeSections) {
            if (section.content.tag !== "Products") {
                continue;
            }
            const productTypeGroup = new ProductTypeGroup({
                id: new ProductTypeGroupId(sectionId),
                preorderSettings: PreorderSettings.create({
                    timezone: this.timezone,
                    preorderSettings: this.isPreorderEnabled
                        ? ((_a = section.preorderSettings) !== null && _a !== void 0 ? _a : this.onlineOrderingSettings.defaultPreorderSettings)
                        : this.onlineOrderingSettings.defaultPreorderSettings,
                    orderingHours: this.orderingHours,
                }),
                visibilityScheduleId: section.visibilitySchedule === null
                    ? null
                    : this.domainScheduleIds.rawFind(section.visibilitySchedule),
                sectionVisibilityService: this.sectionVisibilityService,
            });
            for (const [, item] of section.content.items) {
                if (item.tag === "SingleProduct") {
                    const productTypeId = new ProductTypeId(item.itemProduct);
                    productTypeGroups
                        .getOrCreate(productTypeId, () => [])
                        .push(productTypeGroup);
                }
            }
        }
        return RMap.fromIterable(productTypeGroups.entries.map(([productTypeId, groupsForType]) => [productTypeId, IdMap.fromIterable(groupsForType)]));
    }
}
