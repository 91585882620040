import { RArray } from "../../collections";
import { Tristate } from "../../core";
export default class DebugMaybeAvailablityVistor {
    constructor() {
        this.errors = [];
    }
    visitAvailable(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [
                `Available[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [
                `Unavailable[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitMaybe(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [`Maybe[${availability.unavailabilityReasons.toString()}]`];
        }
    }
    visitAsync(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [`Async[${availability.unavailabilityReasons.toString()}]`];
        }
    }
    visitBoolean(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [
                `Boolean[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitComposite(availability) {
        const nodes = new RArray(availability.availabilities)
            .filtered((availability) => availability.isAvailable.eq(Tristate.Maybe))
            .mapOptional((availability) => {
            const vistor = new DebugMaybeAvailablityVistor();
            availability.accept(vistor);
            return vistor.errors;
        }).raw;
        this.errors = nodes.flat(); //.map(error => `Composite[${availability.unavailabilityReasons}] -> ${error}`);
    }
    visitDependent(availability) {
        const vistitor = new DebugMaybeAvailablityVistor();
        availability.dependency.accept(vistitor);
        //this.errors = vistitor.errors.map(error => `Dependency[${availability.unavailabilityReasons}] -> ${error}`)
        this.errors = vistitor.errors.map((error) => `${availability.unavailabilityReasons.toString()}(${availability.description}) -> ${error}`);
    }
    visitParametrized(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [
                `Parametrized[${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitPreorder(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [`Preorder(${availability.reasons.join(", ")})`];
        }
    }
    visitSchedule(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [
                `Schedule[${availability.availabilitySchedule.id.value}, ${availability.unavailabilityReasons.toString()}]`,
            ];
        }
    }
    visitOrderingHours(availability) {
        if (availability.isAvailable.eq(Tristate.Maybe)) {
            this.errors = [`OrderingHours[]`];
        }
    }
}
