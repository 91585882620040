import { RDay } from "../../api/orderingHoursWidget";
import { IdMap, RArray } from "../../collections";
import { makeImmutable } from "../../core";
import { PMenuSection } from "../../presentation";
import { PreorderInfo } from "../../presentation/PreorderInfo";
export class PMenuSectionParser {
    constructor(params) {
        this.domainMenuSections = params.domainMenuSections;
        this.domainScheduleIds = params.domainScheduleIds;
        this.translatedParser = params.translatedParser;
        this.pMenuSectionItemParser = params.pMenuSectionItemParser;
        this.sectionVisibilityService = params.sectionVisibilityService;
        makeImmutable(this);
    }
    parse(params) {
        var _a;
        const { id, purchaseOrderScope, section } = params;
        const domainSection = this.domainMenuSections.rawFind(id);
        if (!domainSection) {
            return null;
        }
        return new PMenuSection({
            domain: domainSection,
            name: this.translatedParser.parse(section.name),
            description: this.translatedParser.parse(section.description),
            // TODO: do we want to process it here (resize etc.)?
            photoUrn: section.photo,
            items: this.parseSectionItems({
                domainSection,
                section,
                purchaseOrderScope,
            }),
            visibilityScheduleId: section.visibilitySchedule === null
                ? null
                : this.domainScheduleIds.rawFind(section.visibilitySchedule),
            sectionVisibilityService: this.sectionVisibilityService,
            preorderInfo: ((_a = section.preorderSettings) === null || _a === void 0 ? void 0 : _a.tag) === "AbsoluteDate"
                ? new PreorderInfo({
                    from: RDay.fromUTCString(section.preorderSettings.from),
                    to: RDay.fromUTCString(section.preorderSettings.to),
                })
                : null,
        });
    }
    parseSectionItems(params) {
        const { domainSection, purchaseOrderScope, section } = params;
        if (section.content.tag !== "Products") {
            return IdMap.empty();
        }
        const sectionItems = new RArray(section.content.items).mapOptional(([itemId, item]) => this.pMenuSectionItemParser.parse({
            domainSection,
            section,
            id: itemId,
            item,
            purchaseOrderScope,
        }));
        return IdMap.fromIterable(sectionItems);
    }
}
