import { makeAutoObservable } from "mobx";
import { OrderCoupon, OrderCouponCode } from "../../ordering";
import { RArray } from "../../collections";
export class PromotionService {
    constructor(params) {
        this.restaurantId = params.restaurantId;
        this.rpcService = params.rpcService;
        this.promotionParser = params.promotionParser;
        makeAutoObservable(this);
    }
    async getCoupon(couponCode) {
        const res = await this.rpcService.rpc("GetActivePromotionsRequest", {
            couponCodes: [couponCode],
            restaurantId: this.restaurantId.value,
        });
        if (res === null || "Left" in res) {
            return null;
        }
        const promotions = this.promotionParser.parsePromotions(res.Right.promotions);
        const coupons = new RArray(res.Right.coupons).mapOptional((couponPromotion) => {
            // we have to "find", not "get" the promotion - promotions are filtered by the app origin
            // so if coupon promotion is missing it means that promotion has different origin
            const promotion = promotions.rawFind(couponPromotion.promotionId);
            if (!promotion) {
                return null;
            }
            const code = new OrderCouponCode(couponPromotion.code);
            const coupon = new OrderCoupon({
                code,
                source: couponPromotion.source,
                promotion: promotion.domain,
            });
            return { coupon, promotion };
        });
        if (coupons.size > 1) {
            throw new Error("Invalid coupon endpoint response");
        }
        return coupons.find(0);
    }
}
