import { makeImmutable } from "../core";
import { ConditionallyAvailableSpec, TemporarilyUnavailableSpec, } from "../ordering";
export class AvailabilitySpecParser {
    constructor(params) {
        this.parametrizationParser = params.parametrizationParser;
        makeImmutable(this);
    }
    parse(availability, availabilityParametric, availabilitySchedule = null) {
        switch (availability) {
            case "Hidden":
                throw new Error("Hidden elements should be filtered beforehand");
            case "TemporarilyUnavailable":
                return new TemporarilyUnavailableSpec();
            case "Available": {
                const availabilityParametrization = this.parametrizationParser.parse(availabilityParametric);
                return new ConditionallyAvailableSpec({
                    availabilityParametrization,
                    availabilitySchedule,
                });
            }
        }
    }
}
