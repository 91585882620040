import { RArray } from "../../collections";
import { RDate } from "../../core";
import { DateRange } from "../../core/DateRange";
import { AbsoluteDatePreorderSetting } from "./AbsoluteDatePreorderSetting";
import { IntersectionPreorderSettings } from "./IntersectionPreorderSettings";
import { RelativeDatePreorderSetting } from "./RelativeDatePreorderSetting";
import { SameDayPreorderSetting } from "./SameDayPreorderSetting";
import { UnionPreorderSettings } from "./UnionPreorderSettings";
import { PreorderBuffer } from "../PreorderBuffer";
export const PreorderSettings = {
    create(params) {
        switch (params.preorderSettings.tag) {
            case "SameDay":
                return new SameDayPreorderSetting(params.orderingHours);
            case "RelativeDate": {
                return new RelativeDatePreorderSetting({
                    preorderBuffer: PreorderBuffer.create({
                        orderingHours: params.orderingHours,
                        minDays: params.preorderSettings.minDays,
                    }),
                    maxDays: params.preorderSettings.maxDays,
                });
            }
            case "AbsoluteDate": {
                const preorderBuffer = PreorderBuffer.create({
                    orderingHours: params.orderingHours,
                    minDays: params.preorderSettings.minDays,
                });
                const begin = RDate.fromLocalTimeString(params.preorderSettings.from, params.timezone);
                const end = RDate.fromLocalTimeString(params.preorderSettings.to, params.timezone).endOfDay();
                const range = DateRange.fromDates({ begin, end });
                return new AbsoluteDatePreorderSetting({
                    preorderBuffer,
                    range,
                });
            }
        }
    },
    union(settings) {
        return new UnionPreorderSettings(settings);
    },
    intersection(settings) {
        return new IntersectionPreorderSettings(settings);
    },
    /*
     * Large composite union. Groups underlying settings by type as an optimization
     */
    inteligentUnion(settings) {
        let sameDay = null;
        let relativeDate = null;
        const absoluteDates = [];
        settings.forEach((setting) => {
            switch (setting.type) {
                case "SameDay":
                    sameDay = setting;
                    break;
                case "RelativeDate":
                    relativeDate =
                        relativeDate === null ? setting : relativeDate.max(setting);
                    break;
                case "AbsoluteDate":
                    if (!absoluteDates.some((existing) => existing.eq(setting))) {
                        absoluteDates.push(setting);
                    }
                    break;
            }
        });
        const result = absoluteDates;
        if (sameDay !== null) {
            result.push(sameDay);
        }
        if (relativeDate !== null) {
            result.push(relativeDate);
        }
        return this.union(new RArray(result));
    },
};
