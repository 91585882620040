import { makeAutoObservable, runInAction } from "mobx";
import { translate, t } from "../I18n";
export class PGeolocation {
    constructor(params) {
        this.restaurantId = params.restaurantId;
        this.geocodingService = params.geocodingService;
        this.geolocationService = params.geolocationService;
        this._status = { type: "NotRequested" };
        makeAutoObservable(this);
    }
    get status() {
        return this._status;
    }
    get isLoading() {
        return this._status.type === "Loading";
    }
    get error() {
        return this._status.type === "Error" ? this._status.error : null;
    }
    setStatus(status) {
        runInAction(() => {
            this._status = status;
        });
    }
    async locateMe() {
        this.setStatus({ type: "Loading" });
        try {
            const coordinates = await this.geolocationService.getCurrentPosition();
            const streetAddress = await this.geocodingService.reverseGeocode(this.restaurantId, {
                lat: coordinates.latitude,
                lon: coordinates.longitude,
            });
            this.setStatus({ type: "Success", streetAddress });
        }
        catch (e) {
            if (e instanceof GeolocationPositionError) {
                const error = e.code === GeolocationPositionError.PERMISSION_DENIED
                    ? translate(t.geolocator.no_permission)
                    : translate(t.geolocator.failed_to_locate);
                this.setStatus({ type: "Error", error });
            }
            else {
                this.setStatus({
                    type: "Error",
                    error: translate(t.delivery.geocoding_error),
                });
            }
            console.error(e);
        }
    }
}
