import { Sentry } from "../Sentry";
import { associationKey, } from "../core";
import { makeImmutable } from "../core/makeImmutable";
import { AddingDuplicateElementError, UnknownElementError } from "./errors";
/*
 * Restaumatic Set wrapper including helper set operations. Uses `AssociationKey` as an association
 */
export class RSet {
    constructor(raw) {
        this._raw = raw;
        makeImmutable(this);
    }
    eq(other) {
        return this.size === other.size && other.isSubsetOf(this);
    }
    static empty() {
        return new RSet(new Map());
    }
    static singleton(element) {
        return new RSet(new Map([[associationKey(element), element]]));
    }
    static fromIterable(iterable, options) {
        var _a;
        const array = [...iterable].map((el) => [
            associationKey(el),
            el,
        ]);
        const raw = new Map(array);
        const ignoreDuplicates = (_a = options === null || options === void 0 ? void 0 : options.ignoreDuplicates) !== null && _a !== void 0 ? _a : false;
        if (!ignoreDuplicates && raw.size !== array.length) {
            Sentry.logError(AddingDuplicateElementError.fromArray([...iterable]));
        }
        return new RSet(raw);
    }
    [Symbol.iterator]() {
        return this._raw.values();
    }
    get size() {
        return this._raw.size;
    }
    get isEmpty() {
        return this._raw.size === 0;
    }
    includes(element) {
        return this._raw.has(associationKey(element));
    }
    some(predicate) {
        return [...this].some(predicate);
    }
    every(predicate) {
        return [...this].every(predicate);
    }
    reduce(reducer, initialValue) {
        return [...this].reduce(reducer, initialValue);
    }
    filter(predicate) {
        return RSet.fromIterable([...this].filter(predicate));
    }
    isSubsetOf(other) {
        return this.every((element) => other.includes(element));
    }
    union(other) {
        return RSet.fromIterable([...this, ...other], { ignoreDuplicates: true });
    }
    intersection(other) {
        return this.filter((el) => other.includes(el));
    }
    shallowClone() {
        return new RSet(new Map(this._raw.entries()));
    }
    /**
     * Get any value from the Set
     */
    get sample() {
        for (const value of this._raw.values()) {
            return value;
        }
        return null;
    }
    rawFind(key) {
        var _a;
        return (_a = this._raw.get(key)) !== null && _a !== void 0 ? _a : null;
    }
    rawGet(key) {
        const element = this.rawFind(key);
        if (element === null) {
            throw new UnknownElementError();
        }
        return element;
    }
    rawValues() {
        return Array.from(this._raw.values());
    }
    toString() {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        return this.rawValues().toString();
    }
}
