import { FieldTests, LocalStorage, StorageManagerFactory, } from "restaumatic-client";
import { addingProductToTheCartReactionExperiment } from "./experiments/AddingProductToTheCartReaction";
import { menuStickyTabs } from "./experiments/MenuStickyTabs";
/**
 * List of experiments to apply.
 */
const experiments = [
    addingProductToTheCartReactionExperiment,
    menuStickyTabs,
];
/**
 * Global instance for initialising and getting experiments' data.
 * TODO: Move it to the DI global container if ready.
 */
const fieldTest = new FieldTests({
    fieldTestsStorageManager: new StorageManagerFactory({
        storage: LocalStorage.create(),
    }).forFieldTests(),
});
export default {
    init() {
        fieldTest.applyExperiments(experiments);
    },
    getVariant(experiment) {
        return fieldTest.getVariant(experiment);
    },
};
