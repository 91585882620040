import { action, makeAutoObservable } from "mobx";
import { formatAddressAsSingleLine, formatFulfillmentMethodType, GeocodingService, LocalizationProvider, Money, PGeolocation, RestaumaticRPCService, StorageManagerFactory, StorageVersionManager, StreetAddressFormController, WebGeolocationService, } from "..";
import { DeliveryAddressStatus, StreetAddress } from "../ordering";
import { formatOpeningHours } from "./POpeningHours";
export class PRestaurantSelection {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.geocodingService = params.geocodingService;
        this.streetAddressStorageManager = params.streetAddressStorageManager;
        this.apartmentInfoStorageManager = params.apartmentInfoStorageManager;
        this.geolocation = params.geolocation;
        this.initialRestaurants = params.initialRestaurants;
        this.currentRestaurantSlug = params.currentRestaurantSlug;
        this.formAddress = params.streetAddress;
        this.submittedAddress = params.streetAddress;
        this.streetAddressController = params.streetAddressController;
        this._deliveryAddressStatus = DeliveryAddressStatus.createNotRequested();
        makeAutoObservable(this);
    }
    static create(params) {
        const localizationProvider = new LocalizationProvider({
            locale: params.locale,
            fallbackLocale: params.defaultLocale,
            currency: params.currency,
        });
        const rpcService = new RestaumaticRPCService({ url: "" });
        const geocodingService = new GeocodingService({
            rpcService,
            siteId: params.siteId,
        });
        const geolocation = new PGeolocation({
            restaurantId: null,
            geolocationService: new WebGeolocationService(),
            geocodingService: new GeocodingService({
                rpcService,
                siteId: params.siteId,
            }),
        });
        const storageManagerFactory = new StorageManagerFactory({
            storage: params.storage,
        });
        const storageVersionManager = new StorageVersionManager({
            storage: params.storage,
        });
        const currentRestaurantSlugStorageManager = storageManagerFactory.forCurrentRestaurantSlug();
        const streetAddressStorageManager = storageManagerFactory.forStreetAddress();
        const apartmentInfoStorageManager = storageManagerFactory.forApartmentInfo();
        storageVersionManager.checkStorageFormatVersion(streetAddressStorageManager, apartmentInfoStorageManager);
        const streetAddress = StreetAddress.fromStorageData({
            storageData: streetAddressStorageManager.restore(),
            country: params.country,
        });
        return new PRestaurantSelection({
            localizationProvider,
            geocodingService,
            geolocation,
            currentRestaurantSlug: currentRestaurantSlugStorageManager.restore(),
            streetAddress,
            streetAddressStorageManager,
            apartmentInfoStorageManager,
            streetAddressController: new StreetAddressFormController({
                streetAddress,
            }),
            initialRestaurants: params.initialRestaurants,
        });
    }
    get isLoading() {
        return this.deliveryAddressState.type === "Loading";
    }
    get deliveryAddressState() {
        return this._deliveryAddressStatus.state;
    }
    get restaurants() {
        switch (this.deliveryAddressState.type) {
            case "ChooseRestaurant":
            case "RestaurantDelivers":
                return this.deliveryAddressState.restaurants;
            default:
                return this.initialRestaurants;
        }
    }
    get restaurantListItems() {
        return this.restaurants.map((r) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            const deliveryCost = (_b = (_a = r.deliveryInfo) === null || _a === void 0 ? void 0 : _a.deliveryCost) !== null && _b !== void 0 ? _b : null;
            const minOrderValue = (_d = (_c = r.deliveryInfo) === null || _c === void 0 ? void 0 : _c.minOrderValue) !== null && _d !== void 0 ? _d : null;
            const freeThreshold = (_f = (_e = r.deliveryInfo) === null || _e === void 0 ? void 0 : _e.freeDeliveryThreshold) !== null && _f !== void 0 ? _f : null;
            return {
                id: r.id,
                name: r.name,
                slug: (_g = r.slug) !== null && _g !== void 0 ? _g : "",
                address: formatAddressAsSingleLine({
                    street: (_h = r.address.street) !== null && _h !== void 0 ? _h : "",
                    streetNumber: "",
                    city: r.address.city,
                    postCode: r.address.postCode,
                }, true),
                phones: r.contactPhoneNumber !== null
                    ? r.contactPhoneNumber.split(",").map((s) => s.trim())
                    : [],
                email: (_j = r.contactEmail) !== null && _j !== void 0 ? _j : "",
                deliveryTypes: r.fulfillmentMethods
                    .map(formatFulfillmentMethodType)
                    .join(", "),
                menuLink: `/restauracja/${r.slug}`,
                isWithinArea: !!r.deliveryInfo,
                openingHours: formatOpeningHours(r.restaurantHours),
                deliveryCost: deliveryCost !== null
                    ? this.localizationProvider.formatPrice(Money.fromBackend(deliveryCost))
                    : null,
                minOrderValue: minOrderValue !== null
                    ? this.localizationProvider.formatPrice(Money.fromBackend(minOrderValue))
                    : null,
                freeTreshold: freeThreshold !== null
                    ? this.localizationProvider.formatPrice(Money.fromBackend(freeThreshold))
                    : null,
            };
        });
    }
    get deliveringRestaurants() {
        return this.restaurantListItems.filter((item) => item.isWithinArea);
    }
    get notDeliveringRestaurants() {
        return this.restaurantListItems.filter((item) => !item.isWithinArea);
    }
    get geolocationIsLoading() {
        return this.geolocation.status.type === "Loading";
    }
    get geolocationError() {
        return this.geolocation.error;
    }
    submitAddress() {
        // Clear apartment info if street address changed
        if (this.submittedAddress !== null &&
            !this.submittedAddress.eq(this.formAddress)) {
            this.apartmentInfoStorageManager.erase();
        }
        // Store new value in storage
        this.streetAddressStorageManager.store(this.formAddress.storageData);
        // Update submitted address
        this.submittedAddress.setData(this.formAddress.data);
        // Get new address delivery status status
        this._deliveryAddressStatus = DeliveryAddressStatus.createLoading();
        return DeliveryAddressStatus.asyncCreate({
            restaurantId: null,
            streetAddress: this.formAddress,
            geocodingService: this.geocodingService,
        }).then(action((status) => {
            this._deliveryAddressStatus = status;
        }));
    }
    locateMe() {
        return this.geolocation.locateMe().then(() => {
            if (this.geolocation.status.type === "Success") {
                const { city, street, streetNumber } = this.streetAddressController.fields;
                const { streetAddress } = this.geolocation.status;
                city.change(streetAddress.city);
                street.change(streetAddress.street);
                streetNumber.change(streetAddress.streetNumber);
                return true;
            }
            return false;
        });
    }
}
