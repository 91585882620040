import { RArray } from "../../collections";
import { PreorderHours } from "../preorderHours/PreorderHours";
import { RDay } from "./model/RDay";
import { OrderingHoursBoundry } from "./OrderingHoursBoundry";
export class OrderingHoursWidget {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.asap = params.asap;
        this.scope = params.scope;
        this.enableDelayedDelivery = params.enableDelayedDelivery;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        this.productInstanceName = params.productInstanceName;
    }
    getDates(params) {
        const preorderHours = new PreorderHours({
            preorderSettings: params.preorderSettings,
            scope: this.scope,
        });
        let from = params.from;
        if (from.isBefore(RDay.fromRDate(this.scope.now))) {
            from = RDay.fromRDate(this.scope.now);
        }
        const days = [...RDay.range(from, params.to)];
        const dayItems = days.map((day) => {
            return this.createFulfillmentDateItem({
                day,
                preorderHours,
                schedule: params.schedule,
                preorderSettings: params.preorderSettings,
            });
        });
        return dayItems;
    }
    createFulfillmentDateItem(params) {
        var _a, _b;
        const times = this.isPlacingOrderPossibleForTimes()
            ? this.orderingHours.fulfillmentTimes({
                now: this.scope.now,
                date: params.day.toRDate(),
            })
            : RArray.empty();
        let asap = [];
        if (this.asap.asapOptionAvailable(params.preorderSettings) &&
            params.day.toRDate().isToday) {
            const asapConflicts = this.findFulfillmentTimeConflicts(this.scope.now);
            const isAsapScheduleAvailable = (_b = (_a = params.schedule) === null || _a === void 0 ? void 0 : _a.includes(this.scope.now)) !== null && _b !== void 0 ? _b : true;
            if (!isAsapScheduleAvailable) {
                asap = [
                    {
                        type: "Disabled",
                        value: { type: "ASAP" },
                    },
                ];
            }
            else if (asapConflicts.length > 0) {
                asap = [
                    {
                        type: "Conflicting",
                        value: { type: "ASAP" },
                        conflictingProducts: asapConflicts.map((orderLine) => this.productInstanceName.productInstanceName(orderLine.productInstance)),
                    },
                ];
            }
            else {
                asap = [
                    {
                        type: "Available",
                        value: { type: "ASAP" },
                    },
                ];
            }
        }
        const todayEndOfDay = this.orderingHours.endOfDay(this.scope.now);
        const hourItems = asap.concat(times.raw
            .map((time) => {
            return this.createFulfillmentDateTimeItem({
                time,
                todayEndOfDay,
                preorderHours: params.preorderHours,
                schedule: params.schedule,
            });
        })
            .filter((item) => item !== null));
        const items = hourItems.filter((item) => item.type !== "Disabled");
        if (items.length === 0) {
            return {
                type: "Disabled",
                date: params.day,
            };
        }
        else if (items.every((item) => item.type === "Conflicting")) {
            return {
                type: "Conflicting",
                date: params.day,
                items: hourItems,
            };
        }
        else {
            return {
                type: "Available",
                date: params.day,
                items: hourItems,
            };
        }
    }
    createFulfillmentDateTimeItem(params) {
        var _a, _b;
        const conflicts = this.findFulfillmentTimeConflicts(params.time);
        const preorderAvailable = params.preorderHours.canOrderAt(params.time);
        const isScheduleAvailable = (_b = (_a = params.schedule) === null || _a === void 0 ? void 0 : _a.includes(params.time)) !== null && _b !== void 0 ? _b : true;
        const shouldNotShowHoursToday = !this.enableDelayedDelivery &&
            (params.todayEndOfDay !== null
                ? params.time.isBefore(params.todayEndOfDay)
                : params.time.isToday);
        if (shouldNotShowHoursToday) {
            return null;
        }
        if (preorderAvailable) {
            if (!isScheduleAvailable) {
                return {
                    type: "Disabled",
                    value: { type: "OnTime", date: params.time },
                };
            }
            else if (conflicts.length > 0) {
                return {
                    type: "Conflicting",
                    value: { type: "OnTime", date: params.time },
                    conflictingProducts: conflicts.map((orderLine) => this.productInstanceName.productInstanceName(orderLine.productInstance)),
                };
            }
            else {
                return {
                    type: "Available",
                    value: { type: "OnTime", date: params.time },
                };
            }
        }
        else {
            return null;
        }
    }
    isAvailableAt(props) {
        var _a, _b;
        const isPreorderOk = props.preorderSettings
            .dateRanges(this.scope.now)
            .includes(props.date);
        const isScheduleOk = (_b = (_a = props.schedule) === null || _a === void 0 ? void 0 : _a.includes(props.date)) !== null && _b !== void 0 ? _b : true;
        return isPreorderOk && isScheduleOk;
    }
    removeConflictsFromCart(selectedDate) {
        const conflicts = this.findFulfillmentTimeConflicts(selectedDate);
        conflicts.forEach((orderLine) => {
            orderLine.remove();
        });
    }
    findFulfillmentTimeConflicts(date) {
        return this.purchaseOrderPayload.orderLines.raw.filter((orderLine) => !this.isAvailableAt({
            preorderSettings: orderLine.productInstance.preorderSettings,
            schedule: orderLine.productInstance.productWeeklySchedule,
            date,
        }));
    }
    isOnlyAsap(params) {
        return (this.asap.asapOptionAvailable(params.preorderSettings) &&
            !this.isAnyFutureDate(params));
    }
    *dateItemsIterator(params) {
        var _a, _b;
        const boundry = new OrderingHoursBoundry({
            preorderSettings: params.preorderSettings,
            scope: this.scope,
        });
        const maxDay = boundry.maxDay();
        const minDay = boundry.minDay();
        console.log("maxDay", (_a = minDay === null || minDay === void 0 ? void 0 : minDay.toString()) !== null && _a !== void 0 ? _a : "null", (_b = maxDay === null || maxDay === void 0 ? void 0 : maxDay.toString()) !== null && _b !== void 0 ? _b : "null");
        if (minDay === null || maxDay === null) {
            return false;
        }
        const nowDay = RDay.fromRDate(this.scope.now);
        const min = nowDay.isAfter(minDay) ? nowDay : minDay;
        const performanceLimit = minDay.addDays(70);
        const max = maxDay.isAfter(performanceLimit) ? performanceLimit : maxDay;
        if (min.isAfter(max))
            return false;
        for (const day of RDay.range(min, max)) {
            const dateItems = this.getDates({
                preorderSettings: params.preorderSettings,
                schedule: params.schedule,
                from: day,
                to: day,
            });
            for (const dateItem of dateItems) {
                yield dateItem;
            }
        }
    }
    hasAnyAvailableTime(params) {
        for (const dateItem of this.dateItemsIterator(params)) {
            if (dateItem.type === "Available" || dateItem.type === "Conflicting")
                return true;
        }
        return false;
    }
    isAnyFutureDate(params) {
        for (const dateItem of this.dateItemsIterator(params)) {
            if (dateItem.type === "Available" || dateItem.type === "Conflicting") {
                if (dateItem.items.filter((item) => (item.type === "Available" || item.type === "Conflicting") &&
                    item.value.type === "OnTime").length > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    isPlacingOrderPossibleForTimes() {
        const event = this.orderingHours.nextEventAtDate(this.scope.now);
        switch (event.type) {
            case "OrderingBegins":
                return this.allowOrderingInClosedRestaurant;
            case "OrderingEnds":
                return true;
        }
    }
}
