import { NextOrderingTime } from "../api/orderingHoursWidget/NextOrderingTime";
import { IdMap, RArray } from "../collections";
import { makeImmutable, RDate } from "../core";
import { LocalizationProvider } from "../locale";
import { CartCrossSellingCreator, ContactDetailsLock, DisabledCoupon, EnabledCoupon, FirstOrderDiscount, OnlineOrderingHours, ProductCardCreator, ProductInstanceCreator, ProductInstanceCreatorScope, PromotionParser, PurchaseOrderFactory, QrWaiterOrderingHours, Quantity, RestaurantId, SalesChannel, WeeklySchedule, } from "../ordering";
import { OrderingHoursAvailability } from "../ordering/Availability/OrderingHoursAvailability";
import { ProductCardCrossSellingCreator } from "../ordering/CrossSellingCreator/ProductCardCrossSellingCreator";
import { InCartPromotionTypes } from "../ordering/InCartPromotionTypes/InCartPromotionTypes";
import { NewFulfillmentTime } from "../ordering/NewFulfillmentTime";
import { OrderingAvailability } from "../ordering/OrderingAvailability";
import { SectionVisibilityService } from "../ordering/SectionVisibilityService";
import { MenuV2Parser, PPromotionParser, PRestaurantParser, ProductTypeGroupParser, ScheduleParser, TranslatedParser, } from "../parsing";
import { RestaurantParser } from "../parsing/RestaurantParser";
import { ConsentsFormController, ContactFormController, FODFormController, FulfillmentFormController, InvoiceFormController, PaymentFormController, PCart, PCheckout, PCrossSellingCreator, PFulfillmentTime, PFulfillmentTimeModal, PGeolocation, POrderDetails, POrderingAvailability, POrderSummary, PProductCardCreator, PProductInstanceCreator, PPromotionScheme, PPurchaseOrder, PTip, } from "../presentation";
import { PDeliveryAddress } from "../presentation/PDeliveryAddress";
import { ApplePayService, CheckoutInfoService, CheckoutService, FODService, GeocodingService, HolidayService, OrderingAvailabilityService, OrderSummaryService, PromotionService, RestaumaticRPCService, ValidatePhoneNumberService, WebGeolocationService, } from "../services";
import { PurchaseOrderStorageManager, StorageManagerFactory, StorageVersionManager, } from "../storage";
import { CheckoutTracking, OrderItemListTracking } from "../tracking";
export class RestaumaticClient {
    constructor(params) {
        this.purchaseOrderStorageManagerFactory =
            params.purchaseOrderStorageManagerFactory;
        this.isQrWaiterOrder = params.isQrWaiterOrder;
        this.isIosReviewMode = params.isIosReviewMode;
        this.rpcService = params.rpcService;
        this.validatePhoneService = params.validatePhoneService;
        this.geocodingService = params.geocodingService;
        this.geolocationService = params.geolocationService;
        this.checkoutInfoService = params.checkoutInfoService;
        this.holidayService = params.holidayService;
        this.localizationProvider = params.localizationProvider;
        this.checkoutService = params.checkoutService;
        this.applePayService = params.applePayService;
        this.firstOrderDiscountService = params.firstOrderDiscountService;
        this.orderingAvailabilityService = params.orderingAvailabilityService;
        this.domainRestaurantParser = params.domainRestaurantParser;
        this.restaurantParser = params.restaurantParser;
        this.translatedParser = params.translatedParser;
        this.menuConsumer = params.menuConsumer;
        this.qrWaiter = params.qrWaiter;
        this.storageManagerFactory = params.storageManagerFactory;
        makeImmutable(this);
    }
    static create(params) {
        const rpcService = new RestaumaticRPCService({ url: params.rpcUrl });
        const storageManagerFactory = new StorageManagerFactory({
            storage: params.storage,
        });
        const storageVersionManager = new StorageVersionManager({
            storage: params.storage,
        });
        const localizationProvider = new LocalizationProvider({
            locale: params.locale,
            fallbackLocale: params.fallbackLocale,
            currency: params.currency,
        });
        const checkoutInfoService = new CheckoutInfoService({
            localizationProvider,
            rpcService,
        });
        const checkoutService = new CheckoutService({ rpcService });
        const applePayService = ApplePayService.create({
            isApplePaySupported: params.isApplePaySupported,
            mobileAppType: params.mobileAppType,
            currency: params.currency,
        });
        const firstOrderDiscountService = new FODService({ rpcService });
        const geocodingService = new GeocodingService({
            rpcService,
            siteId: params.siteId,
        });
        const geolocationService = new WebGeolocationService();
        const holidayService = new HolidayService({ rpcService });
        const validatePhoneService = new ValidatePhoneNumberService({
            rpcService,
        });
        const orderingAvailabilityService = new OrderingAvailabilityService({
            rpcService,
        });
        const isQrWaiterOrder = params.qrWaiter.isQrWaiterOrder();
        const menuConsumer = isQrWaiterOrder ? "QrWaiter" : "Site";
        const crossSellingConsumer = isQrWaiterOrder ? "QrWaiter" : "Site";
        const salesChannel = SalesChannel.create(isQrWaiterOrder, params.mobileAppType);
        const purchaseOrderFactory = new PurchaseOrderFactory({
            country: params.country,
            salesChannel,
            validatePhoneService,
            geocodingService,
            isQrWaiterOrder,
            isIosApp: params.mobileAppType === "IosApp",
            isIosReviewMode: params.isIosReviewMode,
        });
        const purchaseOrderStorageManagerFactory = (restaurantSlug) => {
            const cartStorageManager = storageManagerFactory.forCart(restaurantSlug);
            const previousCartStorageManager = storageManagerFactory.forPreviousCart(restaurantSlug);
            const purchaseOrderStorageManager = new PurchaseOrderStorageManager({
                country: params.country,
                isQrWaiterOrder,
                purchaseOrderFactory,
                cartStorageManager,
                previousCartStorageManager,
                storageManagerFactory,
            });
            storageVersionManager.checkStorageFormatVersion(purchaseOrderStorageManager);
            return purchaseOrderStorageManager;
        };
        const domainRestaurantParser = new RestaurantParser({
            appOrigin: menuConsumer,
            crossSellingConsumer,
        });
        const translatedParser = new TranslatedParser(localizationProvider);
        const restaurantParser = new PRestaurantParser({
            localizationProvider,
            rpcService,
            isApplePaySupported: params.isApplePaySupported,
            translatedParser,
        });
        return new RestaumaticClient({
            purchaseOrderStorageManagerFactory,
            isQrWaiterOrder,
            rpcService,
            validatePhoneService,
            geocodingService,
            geolocationService,
            checkoutInfoService,
            holidayService,
            localizationProvider,
            checkoutService,
            applePayService,
            firstOrderDiscountService,
            orderingAvailabilityService,
            domainRestaurantParser,
            restaurantParser,
            translatedParser,
            menuConsumer: menuConsumer,
            qrWaiter: params.qrWaiter,
            isIosReviewMode: params.isIosReviewMode,
            storageManagerFactory,
        });
    }
    async orderingHours(params) {
        if (this.isQrWaiterOrder) {
            return new QrWaiterOrderingHours();
        }
        return new OnlineOrderingHours({
            weeklySchedule: WeeklySchedule.fromWeeklyAvailability(params.weeklyAvailability),
            holidays: await params.holidays,
        });
    }
    async createPPurchaseOrder(params) {
        var _a;
        const timezone = params.menuResponse.time_zone;
        const now = RDate.now(timezone);
        /*
         * Further initialization
         */
        if (params.dineInLocation !== null) {
            this.qrWaiter.setDineInLocation(params.dineInLocation);
        }
        const schedulesParser = new ScheduleParser();
        const schedules = schedulesParser.parse(params.menuResponse.catalogue.availabilitySchedules);
        const schedulesAvailableNow = schedules.filter((schedule) => schedule.includes(now)).ids;
        const sectionVisibilityService = new SectionVisibilityService(schedulesAvailableNow);
        const productTypeGroupParser = new ProductTypeGroupParser({
            domainScheduleIds: schedules.ids,
            onlineOrderingSettings: params.menuResponse.online_ordering_settings,
            isPreorderEnabled: params.menuResponse.enable_preorder,
            timezone,
            orderingHours: params.orderingHours,
            sectionVisibilityService,
        });
        const menuParser = new MenuV2Parser({
            appOrigin: this.menuConsumer,
            productTypeGroupParser,
            schedules,
            onlineOrderingSettings: params.menuResponse.online_ordering_settings,
        });
        const domainRestaurant = this.domainRestaurantParser.parse({
            restaurantId: params.restaurantId,
            input: params.menuResponse,
            orderingHours: params.orderingHours,
            automaticCrossSellingWeights: params.automaticCrossSellingWeights,
            checkoutInfo: params.checkoutInfo,
            paymentChannels: params.paymentChannels,
            menuParser,
        });
        const promotionParser = new PPromotionParser({
            translatedParser: this.translatedParser,
            domain: new PromotionParser({
                menuConsumer: this.menuConsumer,
                menu: domainRestaurant.menu,
                timezone,
            }),
        });
        const promotionTypes = promotionParser.parsePromotions(params.menuResponse.promotions);
        const inCartPromotionTypes = new InCartPromotionTypes(IdMap.fromIterable(promotionTypes.objects.map((p) => p.domain)));
        const purchaseOrderStorageManager = this.purchaseOrderStorageManagerFactory(params.menuResponse.slug);
        const cartStorageData = purchaseOrderStorageManager.restoreCart();
        const promotionService = new PromotionService({
            restaurantId: domainRestaurant.id,
            rpcService: this.rpcService,
            promotionParser,
        });
        // NOTICE It is crucial to restore and await cupon before purchaseOrder, because restored promotional items may reference coupon promotion
        const coupon = !domainRestaurant.orderingPolicy.enableCoupons || this.isQrWaiterOrder
            ? new DisabledCoupon()
            : await EnabledCoupon.fromStorageData({
                promotionService,
                inCartPromotionTypes,
                couponCode: (_a = cartStorageData === null || cartStorageData === void 0 ? void 0 : cartStorageData.couponCode) !== null && _a !== void 0 ? _a : null,
            });
        const contactDetailsLock = new ContactDetailsLock(coupon);
        const orderingAvailability = OrderingAvailability.create({
            isQrWaiterOrder: this.isQrWaiterOrder,
            orderingHours: params.orderingHours,
            backendOrderingAvailability: params.backendOrderingAvailability,
            timezone,
        });
        const { purchaseOrder, purchaseOrderScope, pFulfillmentTime, orderingHoursWidget, nextOrderingTime, restaurant, } = purchaseOrderStorageManager.restore({
            restaurant: domainRestaurant,
            now,
            orderingHours: params.orderingHours,
            orderingAvailability,
            dineInLocation: params.dineInLocation,
            cartStorageData,
            inCartPromotionTypes,
            contactDetailsLock,
            enableDelayedDelivery: params.menuResponse.online_ordering_settings.enableDelayedDelivery,
            allowOrderingInClosedRestaurant: params.menuResponse.online_ordering_settings
                .allowOrderingInClosedRestaurant,
            restaurantParser: this.restaurantParser,
            menuResponse: params.menuResponse,
            promotionTypes,
            sectionVisibilityService,
            franchiseName: params.checkoutInfo.franchiseName,
        });
        const pPromotionScheme = new PPromotionScheme({
            showInactivePromotionInstances: params.showInactivePromotionInstances,
            localizationProvider: this.localizationProvider,
            restaurant,
            domain: purchaseOrder.promotionScheme,
            coupon,
        });
        const pOrderDetails = POrderDetails.create({
            restaurant,
            fulfillmentInstructions: purchaseOrder.fulfillmentInstructions,
            promotionScheme: pPromotionScheme,
        });
        const pDeliveryAddress = PDeliveryAddress.create({
            fulfillmentInstructions: purchaseOrder.fulfillmentInstructions,
            restaurantId: restaurant.id,
            geocodingService: this.geocodingService,
        });
        const fulfillmentFormController = FulfillmentFormController.create({
            fulfillmentInstructions: purchaseOrder.fulfillmentInstructions,
            fulfillmentMethodTypes: domainRestaurant.fulfillmentMethods,
            deliveryAddress: pDeliveryAddress,
            viewType: params.viewType,
        });
        const pGeolocation = new PGeolocation({
            restaurantId: restaurant.domain.id,
            geocodingService: this.geocodingService,
            geolocationService: this.geolocationService,
        });
        const pPurchaseOrder = new PPurchaseOrder({
            domain: purchaseOrder,
            orderingAvailability,
            restaurant,
            localizationProvider: this.localizationProvider,
        });
        const orderItemListTracking = OrderItemListTracking.create({
            purchaseOrderPayload: purchaseOrder.payload,
            productTypes: restaurant.productTypes,
            orderItemListStorageManager: this.storageManagerFactory.forOrderItemList(),
        });
        const checkoutTracking = new CheckoutTracking({
            pPurchaseOrder,
            orderItemListTracking,
        });
        return {
            restaurant,
            orderingAvailability,
            purchaseOrder,
            purchaseOrderScope,
            fulfillmentFormController,
            orderItemListTracking,
            checkoutTracking,
            pPurchaseOrder,
            pOrderDetails,
            pFulfillmentTime,
            pGeolocation,
            schedulesAvailableNow,
            orderingHoursWidget,
            nextOrderingTime,
        };
    }
    async getBackendCartData(getMenu) {
        const menuResponse = await getMenu;
        const restaurantId = new RestaurantId(menuResponse.id);
        const requests = Promise.all([
            // dineInLocation
            this.qrWaiter.getDineInLocation(restaurantId),
            // orderingAvailability
            this.orderingAvailabilityService.getOrderingAvailability({
                restaurantId,
                isQrWaiterOrder: this.isQrWaiterOrder,
                isIosReviewMode: this.isIosReviewMode,
            }),
            // checkoutInfo
            this.checkoutInfoService.getCheckoutInfo(restaurantId, this.isIosReviewMode),
            // orderingHours
            this.orderingHours({
                weeklyAvailability: menuResponse.ordering_hours,
                holidays: this.holidayService.getHolidays(restaurantId, menuResponse.time_zone),
            }),
            // automaticCrossSellingWeights
            this.rpcService.rpc("GetAcsWeights", {
                restaurantId: restaurantId.value,
            }),
        ]);
        const [dineInLocation, backendOrderingAvailability, checkoutInfo, orderingHours, automaticCrossSellingWeights,] = await requests;
        return {
            restaurantId,
            menuResponse,
            dineInLocation,
            backendOrderingAvailability,
            checkoutInfo,
            orderingHours,
            automaticCrossSellingWeights,
        };
    }
    async getBackendCheckoutData(getMenu) {
        const menuResponse = await getMenu;
        const restaurantId = new RestaurantId(menuResponse.id);
        const requests = Promise.all([
            // dineInLocation
            this.qrWaiter.getDineInLocation(restaurantId),
            // orderingAvailability
            this.orderingAvailabilityService.getOrderingAvailability({
                restaurantId,
                isQrWaiterOrder: this.isQrWaiterOrder,
                isIosReviewMode: this.isIosReviewMode,
            }),
            // checkoutInfo
            this.checkoutInfoService.getCheckoutInfo(restaurantId, this.isIosReviewMode),
            // orderingHours
            this.orderingHours({
                weeklyAvailability: menuResponse.ordering_hours,
                holidays: this.holidayService.getHolidays(restaurantId, menuResponse.time_zone),
            }),
            // payment channels
            this.checkoutInfoService.getPaymentChannels(restaurantId),
            // automaticCrossSellingWeights
            this.rpcService.rpc("GetAcsWeights", {
                restaurantId: restaurantId.value,
            }),
        ]);
        const [dineInLocation, backendOrderingAvailability, checkoutInfo, orderingHours, paymentChannels, automaticCrossSellingWeights,] = await requests;
        return {
            restaurantId,
            menuResponse,
            dineInLocation,
            backendOrderingAvailability,
            checkoutInfo,
            orderingHours,
            paymentChannels,
            automaticCrossSellingWeights,
        };
    }
    async getBackendPaymentChannels(restaurantId) {
        return this.checkoutInfoService.getPaymentChannels(restaurantId);
    }
    async createCheckout(backendData, placeOrderCallback) {
        const { restaurantId, menuResponse, dineInLocation, backendOrderingAvailability, checkoutInfo, orderingHours, paymentChannels, automaticCrossSellingWeights, } = backendData;
        const { restaurant, purchaseOrder, fulfillmentFormController, checkoutTracking, pPurchaseOrder, pOrderDetails, pGeolocation, pFulfillmentTime, nextOrderingTime, } = await this.createPPurchaseOrder({
            showInactivePromotionInstances: false,
            viewType: "Checkout",
            restaurantId,
            menuResponse,
            dineInLocation,
            automaticCrossSellingWeights,
            backendOrderingAvailability,
            checkoutInfo,
            orderingHours,
            paymentChannels,
        });
        const consentsFormController = new ConsentsFormController({
            consents: purchaseOrder.consents,
        });
        const invoiceFormController = new InvoiceFormController({
            orderReceiver: purchaseOrder.orderReceiver,
        });
        const paymentFormController = new PaymentFormController({
            paymentMediums: restaurant.paymentMediums,
            payment: purchaseOrder.payment,
        });
        const contactFormController = ContactFormController.fromContactDetails(purchaseOrder.contactDetails);
        const tip = PTip.create({
            localizationProvider: this.localizationProvider,
            purchaseOrder: pPurchaseOrder,
        });
        const fieldTestsStorageManager = this.storageManagerFactory.forFieldTests();
        const checkout = new PCheckout({
            purchaseOrder: pPurchaseOrder,
            restaurant,
            checkoutService: this.checkoutService,
            applePayService: this.applePayService,
            localizationProvider: this.localizationProvider,
            orderDetails: pOrderDetails,
            fulfillmentFormController,
            fulfillmentTime: pFulfillmentTime,
            geolocation: pGeolocation,
            paymentFormController,
            contactFormController,
            consentsFormController,
            invoiceFormController,
            checkoutTracking,
            locale: this.localizationProvider.locale,
            placeOrderCallback,
            tip,
            fieldTestsStorageManager,
            nextOrderingTime,
        });
        return checkout;
    }
    async createCart(backendData) {
        const { restaurantId, menuResponse, dineInLocation, backendOrderingAvailability, checkoutInfo, orderingHours, automaticCrossSellingWeights, } = backendData;
        const { restaurant, orderingAvailability, purchaseOrder, purchaseOrderScope, fulfillmentFormController, orderItemListTracking, pPurchaseOrder, pOrderDetails, pFulfillmentTime, pGeolocation, schedulesAvailableNow, orderingHoursWidget, nextOrderingTime, } = await this.createPPurchaseOrder({
            showInactivePromotionInstances: true,
            viewType: "Cart",
            restaurantId,
            menuResponse,
            dineInLocation,
            automaticCrossSellingWeights,
            backendOrderingAvailability,
            checkoutInfo,
            orderingHours,
            paymentChannels: RArray.empty(),
        });
        const firstOrderDiscount = FirstOrderDiscount.create({
            restaurantId: restaurant.id,
            validatePhoneService: this.validatePhoneService,
            firstOrderDiscountService: this.firstOrderDiscountService,
            contactDetails: purchaseOrder.contactDetails,
            restaurantEnableFod: restaurant.enableFod,
            isQrWaiterOrder: this.isQrWaiterOrder,
        });
        const fodFormController = FODFormController.create(firstOrderDiscount);
        const domainCrossSellingCreator = CartCrossSellingCreator.create({
            listProvider: restaurant.domain.crossSellingListProvider,
            menu: restaurant.domain.menu,
            purchaseOrderPayload: purchaseOrder.payload,
            purchaseOrderScope,
        });
        const pDeliveryAddress = PDeliveryAddress.create({
            fulfillmentInstructions: purchaseOrder.fulfillmentInstructions,
            restaurantId: restaurant.id,
            geocodingService: this.geocodingService,
        });
        const cart = new PCart({
            purchaseOrder: pPurchaseOrder,
            restaurant,
            orderDetails: pOrderDetails,
            fulfillmentTime: pFulfillmentTime,
            deliveryAddress: pDeliveryAddress,
            geolocation: pGeolocation,
            firstOrderDiscount,
            fodFormController,
            fulfillmentFormController,
            orderItemListTracking,
            crossSellingCreator: new PCrossSellingCreator({
                domain: domainCrossSellingCreator,
                restaurant,
                orderingHours,
                localizationProvider: this.localizationProvider,
                orderItemListTracking,
            }),
            nextOrderingTime,
        });
        const pOrderingAvailability = new POrderingAvailability({
            domain: orderingAvailability,
            backendOrderingAvailability,
        });
        return {
            cart,
            restaurant,
            orderingHours,
            orderingAvailability: pOrderingAvailability,
            purchaseOrderScope,
            purchaseOrderPayload: purchaseOrder.payload,
            orderItemListTracking,
            menuResponse,
            schedulesAvailableNow,
            orderingHoursWidget,
            fulfillmentInstructions: purchaseOrder.fulfillmentInstructions,
        };
    }
    createProductInstanceCreator(params) {
        const { menu } = params.restaurant.domain;
        return ProductInstanceCreator.create({
            productCategories: menu.productCategories,
            productTypes: menu.productTypes,
            productInstance: params.productInstance,
        });
    }
    createProductCard(params) {
        const menu = params.restaurant.domain.menu;
        const domainCrossSellingCreator = ProductCardCrossSellingCreator.create({
            listProvider: params.restaurant.domain.crossSellingListProvider,
            menu,
            purchaseOrderPayload: params.purchaseOrderPayload,
        });
        const productInstanceCreatorScope = new ProductInstanceCreatorScope(params.purchaseOrderScope);
        const pProductInstanceCreator = PProductInstanceCreator.create({
            orderingHours: params.orderingHours,
            domain: params.productInstanceCreator,
            localizationProvider: this.localizationProvider,
            restaurant: params.restaurant,
        });
        const newFulfillmentTime = new NewFulfillmentTime({
            scope: params.purchaseOrderScope,
            orderingHoursWidget: params.orderingHoursWidget,
            fulfillmentInstructions: params.fulfillmentInstructions,
            weeklyScheduleProvider: {
                get weeklySchedule() {
                    return (pProductInstanceCreator.instance.productWeeklySchedule ||
                        WeeklySchedule.Full);
                },
            },
            preorderSettingsProvider: {
                get preorderSettings() {
                    return pProductInstanceCreator.instance.preorderSettings;
                },
            },
        });
        const nextOrderingTime = new NextOrderingTime({
            orderingHours: params.orderingHours,
            scope: params.purchaseOrderScope,
        });
        const pFulfillmentTime = new PFulfillmentTime({
            domain: newFulfillmentTime,
            modal: new PFulfillmentTimeModal({
                domain: newFulfillmentTime,
                restoreValueOnInit: false,
            }),
        });
        const orderingHoursAvailability = new OrderingHoursAvailability({
            allowOrderingInClosedRestaurant: menu.allowOrderingInClosedRestaurant,
            orderingHours: params.orderingHours,
            scope: productInstanceCreatorScope,
            fulfillmentTime: newFulfillmentTime,
        });
        const domainProductCardCreator = new ProductCardCreator({
            productInstanceCreator: params.productInstanceCreator,
            quantity: Quantity.One,
            crossSellingCreator: domainCrossSellingCreator,
            orderingHoursAvailability,
            isIosReviewMode: this.isIosReviewMode,
        });
        domainProductCardCreator.initWithScope(params.purchaseOrderScope);
        return new PProductCardCreator({
            localizationProvider: this.localizationProvider,
            restaurant: params.restaurant,
            productTypes: menu.productTypes,
            domain: domainProductCardCreator,
            productInstanceCreator: pProductInstanceCreator,
            crossSellingCreator: new PCrossSellingCreator({
                domain: domainCrossSellingCreator,
                orderingHours: params.orderingHours,
                localizationProvider: this.localizationProvider,
                restaurant: params.restaurant,
                orderItemListTracking: params.orderItemListTracking,
            }),
            fulfillmentTime: pFulfillmentTime,
            orderItemListTracking: params.orderItemListTracking,
            nextOrderingTime,
        });
    }
    createOrderSummary(params) {
        const orderSummaryService = new OrderSummaryService({
            rpcService: this.rpcService,
        });
        const fulfillmentTimeStorageManager = this.storageManagerFactory.forFulfillmentTime();
        const cartStorageManager = this.storageManagerFactory.forCart(params.restaurantSlug);
        const previousCartStorageManager = this.storageManagerFactory.forPreviousCart(params.restaurantSlug);
        const orderItemListStorageManager = this.storageManagerFactory.forOrderItemList();
        return new POrderSummary({
            orderSummaryService,
            orderUuid: params.orderUuid,
            localizationProvider: this.localizationProvider,
            cartStorageManager,
            previousCartStorageManager,
            orderItemListStorageManager,
            fulfillmentTimeStorageManager,
            redirectToRestaurant: params.redirectToRestaurant,
        });
    }
}
