import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import { useEffect } from "preact/hooks";
import { ISO_WEEKDAY_NAMES, } from "restaumatic-client";
import { t, translate } from "restaumatic-client";
import Popover from "../../utils/Popover";
function WeeklySchedulePopover(props) {
    const { id, children, weeklySchedule } = props;
    const popoverId = `weekly-schedule-popover-${id}`;
    const weekdayNames = ISO_WEEKDAY_NAMES;
    useEffect(() => {
        // FIXME: create Preact Popover Component that uses `/utils/Popover.ts`
        // We don't have "dispose" popover listeners action
        Popover.init();
    }, []);
    return (h(Fragment, null,
        h("span", { class: "m-availability js-popover-container" },
            h("a", { class: "js-popover", "data-classes": "m-popover m-popover--medium", "data-target": `#${popoverId}` }, children)),
        h("div", { id: popoverId, style: "display: none;" },
            h("span", { class: "m-availability__popover__title--unavailable" }, translate(t.restaurants.menu.unavailable_dish)),
            h("button", { type: "button", class: "close js-dismiss-popover" }, "\u00D7"),
            h("div", { class: "clearfix" }),
            h("div", { class: "multi-column m-popover__content m-availability__sheet" }, weekdayNames.map((weekday) => (h(ScheduleWeekDay, { key: weekday, weekday: weekday, weeklySchedule: weeklySchedule })))))));
}
const ScheduleWeekDay = observer((props) => {
    const { weekday, weeklySchedule } = props;
    const translatedWeekday = translate(t.date.day.abbr_names[weekday.toLowerCase()]);
    const { isEmpty, isFull, timeRanges } = weeklySchedule.dayOfWeek(weekday);
    const showTimeRanges = !isFull && !isEmpty && timeRanges.length > 0;
    return (h("div", null,
        h("div", { class: "m-availability__sheet__header" }, translatedWeekday),
        h("div", { class: "m-availability__sheet__data" },
            isFull && h("i", { class: "icon-ok text-success" }),
            isEmpty && h("i", { class: "icon-remove text-danger" }),
            showTimeRanges && (h("span", { class: "text-success" }, timeRanges.map((timeRange) => timeRange.toString()).join(", "))))));
});
export default observer(WeeklySchedulePopover);
