import { makeAutoObservable } from "mobx";
import { RDay, } from "../../api/orderingHoursWidget";
import { OrderingHoursBoundry } from "../../api/orderingHoursWidget/OrderingHoursBoundry";
import { ASAPFulfillmentTime, OnTimeFulfillmentTime } from "../FulfillmentTime";
export class NewFulfillmentTime {
    constructor(props) {
        this.orderingHoursWidget = props.orderingHoursWidget;
        this.scope = props.scope;
        this.fulfillmentInstructions = props.fulfillmentInstructions;
        this.preorderSettingsProvider = props.preorderSettingsProvider;
        this.weeklyScheduleProvider = props.weeklyScheduleProvider;
        makeAutoObservable(this);
    }
    get today() {
        return RDay.fromRDate(this.scope.now);
    }
    get value() {
        if (this.fulfillmentInstructions.fulfillmentTime === null) {
            return null;
        }
        if (this.fulfillmentInstructions.fulfillmentTime.type === "ASAP") {
            return { type: "ASAP" };
        }
        if (this.fulfillmentInstructions.fulfillmentTime.type === "OnTime") {
            return {
                type: "OnTime",
                date: this.fulfillmentInstructions.fulfillmentTime.date,
            };
        }
        throw new Error("Invalid fulfillment time");
    }
    get isDelivery() {
        return this.fulfillmentInstructions.isDelivery;
    }
    get isAvailableAtSelectedTime() {
        if (this.value === null) {
            return true;
        }
        const valueDate = this.value.type === "ASAP" ? this.scope.now : this.value.date;
        return this.orderingHoursWidget.isAvailableAt({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            schedule: this.weeklyScheduleProvider.weeklySchedule,
            date: valueDate,
        });
    }
    setValue(value) {
        if (this.fulfillmentInstructions.type === "Online") {
            if (value.type === "ASAP") {
                this.fulfillmentInstructions.fulfillmentTime =
                    new ASAPFulfillmentTime();
                this.orderingHoursWidget.removeConflictsFromCart(this.scope.now);
                return;
            }
            else if (value.type === "OnTime") {
                this.fulfillmentInstructions.fulfillmentTime =
                    new OnTimeFulfillmentTime({ date: value.date });
                this.orderingHoursWidget.removeConflictsFromCart(value.date);
                return;
            }
        }
        throw new Error("Invalid fulfillment time");
    }
    getDates(from, to) {
        console.log("*getDates", from.raw.raw.toString(), to.raw.raw.toString());
        if (from.isAfter(to)) {
            console.log("Invalid date range", from.raw.raw.toString(), to.raw.raw.toString());
            throw new Error(`Invalid date range ${from.raw.raw.toString()} to ${to.raw.raw.toString()}`);
        }
        const result = this.orderingHoursWidget.getDates({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            schedule: this.weeklyScheduleProvider.weeklySchedule,
            from,
            to,
        });
        return result;
    }
    get isSameDayOnly() {
        return this.preorderSettingsProvider.preorderSettings.isSameDayOnly;
    }
    get isOnlyAsap() {
        return this.orderingHoursWidget.isOnlyAsap({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            schedule: this.weeklyScheduleProvider.weeklySchedule,
        });
    }
    get preorderSettingsBoundry() {
        return new OrderingHoursBoundry({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            scope: this.scope,
        });
    }
    get minDay() {
        return this.preorderSettingsBoundry.minDay() || this.today;
    }
    get maxDay() {
        return this.preorderSettingsBoundry.maxDay() || this.today;
    }
    isAnyFutureDate(params) {
        const result = this.orderingHoursWidget.isAnyFutureDate(params);
        return result;
    }
    get hasAnyAvailableTime() {
        const result = this.orderingHoursWidget.hasAnyAvailableTime({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            schedule: this.weeklyScheduleProvider.weeklySchedule,
        });
        return result;
    }
    hasNext(after) {
        var _a, _b;
        if (after === null) {
            return false;
        }
        return ((_b = (_a = new OrderingHoursBoundry({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            scope: this.scope,
        })
            .maxDay()) === null || _a === void 0 ? void 0 : _a.isAfter(after)) !== null && _b !== void 0 ? _b : false);
    }
    hasPrevious(before) {
        var _a, _b;
        if (before === null || before.isBefore(this.today)) {
            return false;
        }
        return ((_b = (_a = new OrderingHoursBoundry({
            preorderSettings: this.preorderSettingsProvider.preorderSettings,
            scope: this.scope,
        })
            .minDay()) === null || _a === void 0 ? void 0 : _a.isBefore(before)) !== null && _b !== void 0 ? _b : false);
    }
}
