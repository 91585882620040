import { IdMap, RArray, RMap, RMutableMap, RSet } from "../../collections";
import { IdentifiableMap } from "../../collections/IdentifiableMap";
import { makeImmutable } from "../../core/makeImmutable";
import { CustomParameterInstance } from "../CustomParameterInstance";
import { CustomParameterMultiSet } from "../ParameterMultiSet";
/**
 * A single combination of different internal parameters.
 *
 * For example: "size: 30cm, dough: thick"
 *
 * The restaurant owner can define their own custom parameters, such as "pizza size"
 */
export class CustomParameterSet {
    constructor(parameterValues) {
        this.parameterValues = parameterValues;
        makeImmutable(this);
    }
    static fromRMap(parameterValues) {
        return new CustomParameterSet(parameterValues);
    }
    static fromChoices(parameterChoices) {
        for (const [parameterType, parameterChoiceId] of parameterChoices) {
            if (!parameterType.includesChoice(parameterChoiceId)) {
                throw new Error(`Parameter type ${parameterType.id.toString()} does not allow choice ${parameterChoiceId.toString()}`);
            }
        }
        return CustomParameterSet.fromRMap(parameterChoices);
    }
    static singleton(parameterType, parameterChoiceId) {
        return CustomParameterSet.fromRMap(RMap.singleton(parameterType, parameterChoiceId));
    }
    get choices() {
        return this.parameterValues;
    }
    static fromInstances(customParameterInstances) {
        const parameterChoices = customParameterInstances.objects.flatMap((customParameterInstance) => customParameterInstance.selectedChoice
            ? new RArray([
                [
                    customParameterInstance.customParameterType,
                    customParameterInstance.selectedChoice,
                ],
            ])
            : RArray.empty());
        return CustomParameterSet.fromChoices(RMap.fromIterable(parameterChoices));
    }
    get parameterInstances() {
        return IdentifiableMap.fromIterable("typeId", [...this.parameterValues].map(([parameterType, parameterChoiceId]) => CustomParameterInstance.create(parameterType, parameterChoiceId)));
    }
    get parameterTypes() {
        return IdMap.fromIterable(this.parameterValues.keys);
    }
    get choiceIds() {
        return RSet.fromIterable(this.parameterValues.values);
    }
    get isEmpty() {
        return this.parameterValues.isEmpty;
    }
    static empty() {
        return CustomParameterSet.fromRMap(RMap.empty());
    }
    unify(other) {
        const matchingParameterMap = RMutableMap.empty();
        const incompatibleParameterMap = RMutableMap.empty();
        for (const [parameterTypeId, thisValue] of this.parameterValues) {
            const otherValue = other.parameterValues.find(parameterTypeId);
            if (otherValue === null) {
                continue;
            }
            if (!thisValue.eq(otherValue)) {
                incompatibleParameterMap
                    .getOrCreate(parameterTypeId, () => [])
                    .push(otherValue);
                continue;
            }
            matchingParameterMap
                .getOrCreate(parameterTypeId, () => [])
                .push(otherValue);
        }
        const matchingParameters = matchingParameterMap
            .freeze()
            .map((v) => RSet.fromIterable(v, { ignoreDuplicates: true }));
        if (incompatibleParameterMap.isEmpty) {
            const result = this.parameterValues.filterWithKey((k) => !matchingParameters.includes(k));
            return {
                type: "success",
                matchingParameters: new CustomParameterMultiSet(matchingParameters),
                value: new CustomParameterSet(result),
            };
        }
        const incompatibleParameters = incompatibleParameterMap
            .freeze()
            .map((v) => RSet.fromIterable(v, { ignoreDuplicates: true }));
        return {
            type: "failure",
            matchingParameters: new CustomParameterMultiSet(matchingParameters),
            incompatibleParameters: new CustomParameterMultiSet(incompatibleParameters),
        };
    }
    /**
     * Unites two CustomParameterSets
     *
     * Fails if predicates from one set conflict with predicates from another set
     */
    union(other) {
        const added = [];
        for (const [parameterTypeId, otherValue] of other.parameterValues) {
            const thisValue = this.parameterValues.find(parameterTypeId);
            if (thisValue !== null) {
                if (!thisValue.eq(otherValue)) {
                    return null;
                }
            }
            else {
                added.push([parameterTypeId, otherValue]);
            }
        }
        return new CustomParameterSet(this.parameterValues.union(RMap.fromIterable(added)));
    }
    find(customParameterType) {
        return this.parameterValues.find(customParameterType);
    }
    findById(customParameterTypeId) {
        return this.parameterValues.rawFind(customParameterTypeId.value);
    }
}
