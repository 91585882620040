import { makeImmutable } from "../../core";
export class ApplePayToken {
    constructor(token) {
        this.token = token;
        makeImmutable(this);
    }
    get checkoutData() {
        return this.token;
    }
}
